/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Faq from "./Faq";
import bgImage from "assets/images/vastgoedverhuurmakelaardij/regus-kantoor-foto.webp";
import Container from "@mui/material/Container";
import MKTypography from "../../../components/MKTypography";
import Card from "@mui/material/Card";
import CallToAction from "../../../examples/CallToAction";
import NewOffersBanner from "../../../examples/NewOffersBanner";
import Socials from "../../../examples/Socials";

function RentalAndManagementFaq() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <MKBox minHeight="15vh"></MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Faq />
        <CallToAction />
        <NewOffersBanner />
        <Socials />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default RentalAndManagementFaq;
