import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { Provider } from "react-redux";
import store from "./redux/store";
import axios from "axios";
import { createBrowserHistory } from "history";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

const history = createBrowserHistory();
const root = document.getElementById("root");

if (root) {
  // Set up the browser history with the updated location (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    root
  );
}
