/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Box from "@mui/material/Box";

const algemene_voorwaarden = [
  {
    title: "Artikel 1 Definities en toepasselijkheid",
    content: [
      "Deze algemene voorwaarden zijn van toepassing op alle overeenkomsten die Vastgoed\n" +
        "Verhuurmakelaardij B.V., hierna te noemen ‘Opdrachtnemer’, sluit met haar Opdrachtgevers in de\n" +
        "hoedanigheid van verhuurder, hierna te noemen ‘Opdrachtgever’, eveneens zijn deze algemene\n" +
        "voorwaarden van toepassing op alle dienstverlening en overige (rechts) handelingen tussen\n" +
        "Opdrachtnemer en Opdrachtgever. ",
      "Deze algemene voorwaarden zijn ook van toepassing op alle dienstverlening door Opdrachtnemer aan\n" +
        "Opdrachtgever voor de uitvoering waarvan derden worden betrokken. Onder derden worden mede\n" +
        "verstaan vennootschappen behorend tot het concern waartoe Vastgoed Verhuurmakelaardij B.V. behoort.",
      "Onder ‘overeenkomst’ wordt in deze algemene voorwaarden de bemiddelingsopdracht tot verhuur\n" +
        "verstaan, die tot stand komt tussen Opdrachtgever en Opdrachtnemer, waarbij Opdrachtnemer\n" +
        "bezichtigingen van een door Opdrachtgever te verhuren (woon-)ruimte uitvoert met kandidaat huurders\n" +
        "gericht op het tot stand komen van een huurovereenkomst tussen Opdrachtgever en kandidaathuurder(s).",
      "Ingeval in een overeenkomst afgeweken wordt van de algemene voorwaarden gelden uitdrukkelijk de\n" +
        "bepalingen, zoals deze schriftelijk in de overeenkomst zijn opgenomen.",
      "Onder ‘schriftelijk’ wordt in deze algemene voorwaarden eveneens verstaan: per e-mail, per fax of enige\n" +
        "andere wijze van communicatie die met het oog op de stand der techniek en de in het maatschappelijk\n" +
        "verkeer geldende opvattingen hiermee gelijk kan worden gesteld.",
      "Het om welke reden dan ook niet van toepassing zijn van een (deel van een) bepaling van deze algemene\n" +
        "voorwaarden laat de toepasselijkheid van de overige bepalingen onverlet.",
      "Opdrachtnemer heeft het recht - al dan niet bij gewijzigde regelgeving - deze algemene voorwaarden aan\n" +
        "te passen.",
      "Opdrachtgever kan zich niet beroepen op het feit dat de algemene voorwaarden niet ter hand zijn gesteld,\n" +
        "indien Opdrachtnemer deze algemene voorwaarden al eerder bij een andere transactie aan Opdrachtgever\n" +
        "heeft verstrekt.",
      "De toepasselijkheid van algemene voorwaarden van Opdrachtgever wordt door Opdrachtnemer\n" +
        "uitdrukkelijk van de hand gewezen.",
    ],
  },
  {
    title: "Artikel 2 Aanbiedingen, offertes",
    content: [
      "Alle aanbiedingen, offertes van Opdrachtnemer zijn vrijblijvend, tenzij ze zijn opgenomen in een door\n" +
        " Opdrachtnemer opgestelde en rechtsgeldig ondertekende bemiddelingsopdracht tot verhuur.",
    ],
  },
  {
    title: "Artikel 3 Overeenkomst, opdracht",
    content: [
      "De overeenkomst komt tot stand op het moment dat de door Opdrachtnemer en Opdrachtgever\n" +
        " ondertekende bemiddelingsopdracht door Opdrachtnemer retour is ontvangen. De overeenkomst\n" +
        " is gebaseerd op ten tijde daarvan door de Opdrachtgever aan Opdrachtnemer verstrekte informatie. De\n" +
        " overeenkomst wordt geacht juist en volledig te zijn weergegeven.",
      " Opdrachtnemer zal zich naar beste kunnen en weten inspannen om het door Opdrachtgever gewenste\n" +
        " c.q. beoogde resultaat te bereiken. Dit is te allen tijde een inspanningsverplichting van Opdrachtnemer en\n" +
        " geen resultaatsverplichting. Indien voornoemd resultaat uitblijft, ontslaat dit Opdrachtgever derhalve niet\n" +
        " van zijn verplichtingen jegens Opdrachtnemer, met uitzondering van eventuele verplichtingen die door\n" +
        " partijen uitdrukkelijk gekoppeld zijn aan het bereiken van een beoogd resultaat.",
      "Indien de overeenkomst mondeling is verstrekt, dan wel (nog) niet getekend retour is ontvangen\n" +
        " wordt de overeenkomst geacht te zijn tot stand gekomen onder toepasselijkheid van deze\n" +
        " algemene voorwaarden op het moment dat Opdrachtnemer op verzoek van Opdrachtgever met de\n" +
        " uitvoering van de overeenkomst is gestart. ",
      "De overeenkomst komt in plaats van, en vervangt, alle eerdere voorstellen, correspondentie, afspraken of\n" +
        " andere communicatie schriftelijk dan wel mondeling gedaan. ",
      "De door de Opdrachtgever, na het aangaan van de overeenkomst, alsnog gewenste wijzigingen\n" +
        " in de uitvoering daarvan, nadere instructies en overige mededelingen met betrekking tot de\n" +
        " overeenkomst, moeten door de Opdrachtgever tijdig en schriftelijk aan Opdrachtnemer ter kennis\n" +
        " worden gebracht.",
      "Aanvullingen of wijzigingen op de algemene voorwaarden of anderszins wijzigingen of aanvullingen op de\n" +
        " overeenkomst worden eerst na schriftelijke bevestiging door Opdrachtnemer bindend.",
      "Wijzigingen in de oorspronkelijke overeenkomst, van welke aard ook, door of namens de\n" +
        "Opdrachtgever aangebracht, die hogere kosten veroorzaken dan waarmee in de oorspronkelijke\n" +
        "overeenkomst rekening kon worden gehouden, worden aan de Opdrachtgever als meerwerk in rekening\n" +
        "gebracht.",
      "De overeenkomst wordt aangegaan voor onbepaalde tijd, tenzij uit de inhoud, aard of strekking van de\n" +
        " verleende opdracht voortvloeit dat deze voor een bepaalde tijd is aangegaan.",
      "Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft Opdrachtnemer het\n" +
        " recht bepaalde werkzaamheden / leveringen door derden te laten verrichten. Een en ander ter\n" +
        " beoordeling van Opdrachtnemer.",
    ],
  },
  {
    title: "Artikel 4 Uitvoering van de overeenkomst",
    content: [
      "Alle werkzaamheden die door Opdrachtnemer worden verricht, worden zorgvuldig en op deskundige wijze\n" +
        " uitgevoerd door Opdrachtnemer overeenkomstig de hiervoor in haar branche geldende maatstaven. ",
      "Ten aanzien van de beoogde werkzaamheden is nadrukkelijk sprake van een inspanningsverplichting aan\n" +
        " de zijde van de Opdrachtnemer, tenzij uitdrukkelijk anders is bepaald.",
      "Opdrachtnemer bepaalt de wijze waarop de overeenkomst wordt uitgevoerd, doch\n" +
        " neemt daarbij de door Opdrachtgever kenbaar gemaakte eisen zoveel mogelijk in acht",
      "Opdrachtnemer kan eerst meer werkzaamheden verrichten en aan Opdrachtgever in rekening\n" +
        " brengen dan waartoe de bemiddelingsopdracht is verstrekt, indien Opdrachtgever hiervoor vooraf\n" +
        " toestemming heeft verleend. Indien Opdrachtnemer echter uit hoofde van zijn wettelijke zorgplicht\n" +
        " gehouden is om meerwerk te verrichten is hij gerechtigd dit aan Opdrachtgever in rekening te brengen,\n" +
        " ook indien Opdrachtgever voor het verrichten van het meerwerk vooraf niet expliciet zijn toestemming\n" +
        " heeft verleend.",
      "Opdrachtnemer kan niet eerder verplicht worden met de uitvoering van de werkzaamheden te beginnen,\n" +
        " dan nadat alle daarvoor noodzakelijke gegevens in haar bezit zijn en zij de eventueel overeengekomen\n" +
        " (vooruit)betaling heeft ontvangen.",
      "Opgegeven termijnen waarbinnen door Opdrachtnemer de werkzaamheden moeten zijn verricht c.q. de\n" +
        " bescheiden en/of diensten moeten zijn geleverd kunnen nimmer worden beschouwd als fatale termijnen.\n" +
        " Omdat de duur van de overeenkomst kan worden beïnvloed door allerlei factoren, zoals de\n" +
        " kwaliteit van de informatie die Opdrachtgever verstrekt en de medewerking die wordt verleend, zijn de\n" +
        " termijnen waarbinnen de werkzaamheden dienen te zijn afgerond, slechts te beschouwen als fatale\n" +
        " termijnen indien dit uitdrukkelijk schriftelijk is overeengekomen.",
      "Indien Opdrachtnemer haar verplichtingen uit de overeenkomst niet dan wel niet tijdig nakomt, dient zij\n" +
        " schriftelijk in gebreke te worden gesteld.",
    ],
  },
  {
    title: "Artikel 5 Verplichtingen Opdrachtgever",
    content: [
      "Opdrachtgever dient ervoor zorg te dragen dat alle gegevens en bescheiden, welke Opdrachtnemer\n" +
        " overeenkomstig zijn oordeel nodig heeft voor het correct en tijdig uitvoeren van de overeenkomst, tijdig\n" +
        " en in de door Opdrachtnemer gewenste vorm en wijze aan Opdrachtnemer ter beschikking worden\n" +
        " gesteld.",
      "Tenzij uit de aard van de overeenkomst anders voortvloeit is Opdrachtgever verantwoordelijk voor\n" +
        " de juistheid, volledigheid en betrouwbaarheid van de aan Opdrachtnemer ter beschikking gestelde\n" +
        " gegevens en bescheiden, ook indien deze via of van derden afkomstig zijn. Opdrachtgever garandeert\n" +
        " tevens dat alle aangeleverde gegevens en bescheiden rechtmatig zijn verkregen en dat hiermee geen\n" +
        " inbreuk wordt gemaakt op de rechten van derden. Opdrachtgever vrijwaart Opdrachtnemer voor gevolgen\n" +
        " voortvloeiende uit het niet juist en/of onvolledig of onrechtmatig verkregen zijn van de gegevens.",
      "Opdrachtgever dient ervoor zorg te dragen dat Opdrachtnemer onverwijld wordt geïnformeerd over feiten\n" +
        " en omstandigheden die in verband met de correcte uitvoering van de overeenkomst van belang\n" +
        " kunnen zijn. ",
      "Indien niet tijdig aan de in dit artikel genoemde verplichtingen is voldaan, is Opdrachtnemer gerechtigd de\n" +
        " uitvoering van de overeenkomst op te schorten tot het moment dat de Opdrachtgever wel aan deze\n" +
        " verplichtingen heeft voldaan.",
      "Opdrachtgever dient zelf afspraken te maken met en/of toestemming – indien van toepassing – voor\n" +
        " verhuur te verkrijgen van: hypotheekverstrekkers, gemeentelijke overheid, verzekeraars, verenigingen\n" +
        " van eigenaren e.d. en is aansprakelijk voor eventuele aanspraken van welke aard\n" +
        " dan ook van deze en andere partijen, welke kunnen voorvloeien uit de door de Opdrachtgever – onder\n" +
        " bemiddeling door Opdrachtnemer - met de huurder gesloten huurovereenkomst. Opdrachtgever vrijwaart\n" +
        " Opdrachtnemer uitdrukkelijk voor dergelijke aanspraken.",
      "Het is Opdrachtgever verboden informatie, die hij ter zake van de overeenkomst van Opdrachtnemer\n" +
        " ontvangt, zonder voorafgaande toestemming van Opdrachtnemer, aan derden door te geven, zulks op\n" +
        " straffe van vergoeding van alle schade, die hierdoor voor Opdrachtnemer zal ontstaan.",
      "Het is Opdrachtgever niet toegestaan verplichtingen uit de overeenkomst over te dragen aan derden.\n" +
        " Opdrachtgever blijft te allen tijde aansprakelijk voor de verplichtingen, tenzij Opdrachtnemer en\n" +
        " Opdrachtgever expliciet anders overeenkomen.",
      "Opdrachtgever staat er voor in dat Opdrachtnemer onbeperkt toegang heeft / krijgt tot het te verhuren\n" +
        " onroerend goed om de (technische) staat ervan te kunnen inspecteren en bezichtigingen met\n" +
        " (kandidaat-)huurders uit te voeren. ",
    ],
  },
  {
    title: "Artikel 6 Geheimhouding",
    content: [
      "Tenzij enige wetsbepaling, voorschrift of andere (beroeps)regel haar daartoe verplicht, is Opdrachtnemer\n" +
        " verplicht tot geheimhouding tegenover derden ten aanzien van vertrouwelijke informatie die is verkregen\n" +
        " van Opdrachtgever. Opdrachtgever kan ter zake ontheffing verlenen.",
      "Behoudens schriftelijke toestemming van Opdrachtgever is Opdrachtnemer gerechtigd de vertrouwelijke\n" +
        " informatie die aan haar door Opdrachtgever ter beschikking wordt gesteld niet aan te wenden tot een\n" +
        " ander doel dan waarvoor zij werd verkregen. Hiertoe wordt echter een uitzondering gemaakt, in het geval\n" +
        " Opdrachtnemer voor zichzelf optreedt in een tucht-, civiele- of strafprocedure waarbij deze van belang kan\n" +
        " zijn. ",
      "Tenzij sprake is van enige wetsbepaling, voorschrift of andere (beroeps)regel die Opdrachtgever verplicht\n" +
        " tot openbaarmaking of daartoe door Opdrachtnemer voorafgaande schriftelijke toestemming is verleend,\n" +
        " zal Opdrachtgever de inhoud van rapporten, adviezen of andere al dan niet schriftelijke uitingen van de\n" +
        " Opdrachtnemer, niet aan derden openbaren. De Opdrachtgever zal er eveneens voor zorgdragen dat\n" +
        " derden niet van de in de vorige zin bedoelde inhoud kennis kunnen nemen.",
      "Opdrachtgever en Opdrachtnemer zullen hun verplichtingen op grond van dit artikel opleggen aan door\n" +
        " hen in te schakelen derden. ",
    ],
  },
  {
    title: "Artikel 7 Intellectuele eigendom",
    content: [
      "Opdrachtnemer behoudt zich alle rechten van intellectuele eigendom voor met betrekking tot producten\n" +
        " van de geest welke hij gebruikt of heeft gebruikt en/of ontwikkelt en/of heeft ontwikkeld, en ten aanzien\n" +
        " waarvan hij de auteursrechten of andere rechten van intellectuele eigendom heeft of geldend kan maken",
      "Het is de Opdrachtgever uitdrukkelijk verboden die producten, waaronder mede begrepen\n" +
        " computerprogramma’s, systeemontwerpen, werkwijzen, adviezen, (model)contracten en andere\n" +
        " geestesproducten van de Opdrachtnemer, een en ander in de ruimste zin des woords, al dan niet met\n" +
        " inschakeling van derden te verveelvoudigen, te openbaren of te exploiteren. Verveelvoudiging en/of\n" +
        " openbaarmaking en/of exploitatie is slechts na uitdrukkelijk verkregen schriftelijke toestemming van\n" +
        " Opdrachtnemer toegestaan. Opdrachtgever heeft het recht de schriftelijke documenten te\n" +
        " vermenigvuldigen voor gebruik binnen zijn eigen organisatie, voor zover passend binnen het doel van de\n" +
        " opdracht. Ingeval van tussentijdse beëindiging van de overeenkomst, is het voorgaande van\n" +
        " overeenkomstige toepassing. ",
    ],
  },
  {
    title: "Artikel 8. Bemiddelingsvergoeding, kosten en declaraties",
    content: [
      "Opdrachtnemer en Opdrachtgever komen in de bemiddelingsopdracht tot verhuur de hoogte van de\n" +
        " bemiddelingsvergoeding voor Opdrachtnemer overeen. Naast de bemiddelingsvergoeding is\n" +
        " Opdrachtnemer gerechtigd onkosten en kosten van door Opdrachtnemer ingeschakelde derden in\n" +
        " rekening te brengen bij Opdrachtgever. ",
      "Alle tarieven luiden in Euro’s en zijn exclusief omzetbelasting en andere heffingen welke van\n" +
        " overheidswege (kunnen) worden opgelegd.",
      "De bemiddelingsvergoeding van Opdrachtnemer, zo nodig vermeerderd met gedeclareerde kosten van\n" +
        " ingeschakelde derden, wordt per maand of na volbrenging van de werkzaamheden aan de Opdrachtgever\n" +
        " in rekening gebracht, tenzij de Opdrachtgever en Opdrachtnemer hierover andere afspraken hebben\n" +
        " gemaakt. ",
      "De uit de vertraging in de uitvoering van de overeenkomst voortvloeiende kosten, ontstaan door het niet,\n" +
        " niet tijdig of niet behoorlijk ter beschikking stellen van de verzochte gegevens, bescheiden, faciliteiten\n" +
        " en/of personeel zijn voor rekening van de Opdrachtgever. ",
      "Alle onkosten welke door Opdrachtnemer worden gemaakt op verzoek van Opdrachtgever komen geheel\n" +
        " voor rekening van Opdrachtgever, tenzij partijen uitdrukkelijk schriftelijk anders zijn overeengekomen.",
    ],
  },
  {
    title: "Artikel 9 Betaling",
    content: [
      "Opdrachtnemer is bevoegd om - ter zake de nakoming van financiële verplichtingen van Opdrachtgever –\n" +
        " vooruitbetaling of zekerheid van Opdrachtgever te verlangen, alvorens met de te verrichten\n" +
        " werkzaamheden te starten dan wel deze voort te zetten.",
      "Betaling dient door Opdrachtgever zonder aftrek, korting of schuldverrekening te geschieden binnen 14\n" +
        " dagen na factuurdatum. Deze termijn geldt als fatale termijn. Betaling dient te geschieden in op de factuur\n" +
        " aangegeven valuta, door middel van overmaking ten gunste van een door Opdrachtnemer aan te wijzen\n" +
        " bankrekening. Bezwaren tegen de hoogte van de ingediende facturen schorten de betalingsverplichting\n" +
        " van Opdrachtgever niet op.",
      "Bij overschrijding van de onder 9.2 genoemde termijn, is Opdrachtgever, na door Opdrachtnemer ten\n" +
        " minste éénmaal te zijn aangemaand om binnen een redelijke termijn te betalen, van rechtswege in\n" +
        " verzuim. In dat geval is Opdrachtgever, vanaf de datum waarop de verschuldigde som opeisbaar is\n" +
        " geworden tot aan het tijdstip van betaling over het verschuldigde wettelijke rente verschuldigd.",
      "Alle kosten van invordering komen, nadat Opdrachtgever in verzuim is, zowel gerechtelijk als\n" +
        " buitengerechtelijk, ten laste van Opdrachtgever. De buitengerechtelijke kosten worden vastgesteld op ten\n" +
        " minste 15% van de hoofdsom en rente, onverminderd het recht van Opdrachtnemer de werkelijke\n" +
        " buitengerechtelijke kosten die dit bedrag te boven gaan, te vorderen. De gerechtelijke kosten omvatten de\n" +
        " volledige door Opdrachtnemer gemaakte kosten, ook als deze het wettelijke liquidatietarief te boven gaan. ",
      "Opdrachtnemer heeft het recht, zonder nadere ingebrekestelling of rechterlijke tussenkomst, de\n" +
        " overeenkomst geheel of gedeeltelijk te ontbinden als Opdrachtgever van rechtswege in verzuim is. Dit laat\n" +
        " het recht op betaling door Opdrachtgever onverlet.",
    ],
  },
  {
    title: "Artikel 10 Reclames",
    content: [
      "Opdrachtgever is verplicht direct na ontvangst van de bemiddelingsopdracht tot verhuur en overige\n" +
        " Bescheiden, waaronder ook de door bemiddeling van de Opdrachtnemer opgestelde huurovereenkomst\n" +
        " tussen Opdrachtgever en huurder, over te gaan tot controle hiervan. Eventuele zichtbare fouten of\n" +
        " onvolkomenheden dienen uiterlijk binnen 2 werkdagen na ontvangst schriftelijk te worden gemeld aan\n" +
        " Opdrachtnemer. ",
      "Reclames met betrekking tot de verrichte werkzaamheden dienen door Opdrachtgever schriftelijk binnen\n" +
        " 30 dagen na uitvoering van de werkzaamheden aan Opdrachtnemer kenbaar te worden gemaakt. ",
      "Reclames als in het eerste lid bedoeld, schorten de betalingsverplichting van Opdrachtgever niet op. ",
    ],
  },
  {
    title: "Artikel 11 Opzegging",
    content: [
      "Opdrachtgever en Opdrachtnemer kunnen de overeenkomst te allen tijde (tussentijds) per aangetekend\n" +
        " schrijven opzeggen met inachtneming van een redelijke opzegtermijn, tenzij de redelijkheid en billijkheid\n" +
        " zich tegen beëindiging of beëindiging op een dergelijke termijn verzetten. ",
      "De overeenkomst mag door zowel Opdrachtgever als Opdrachtnemer per aangetekend schrijven\n" +
        " (tussentijds) worden opgezegd zonder inachtneming van een opzegtermijn in het geval de andere partij\n" +
        " niet in staat is om haar schulden te voldoen of indien een curator, bewindvoerder of vereffenaar is\n" +
        " benoemd, de andere partij een schuldsanering treft, of om enige andere reden haar activiteiten staakt of\n" +
        " indien de andere partij het ontstaan van een van de bovengenoemde omstandigheden bij de ene partij\n" +
        " redelijkerwijs aannemelijk acht of inden er een situatie is ontstaan die onmiddellijke beëindiging\n" +
        " rechtvaardigt in het belang van de opzeggende partij. ",
      "Indien tot (tussentijdse) opzegging is overgegaan door Opdrachtgever, heeft Opdrachtnemer recht op\n" +
        " vergoeding van de reeds door haar verrichte werkzaamheden en gemaakte onkosten, alsmede van\n" +
        " additionele kosten die zij redelijkerwijs moet maken ten gevolge van de vroegtijdige beëindiging van de\n" +
        " bemiddelsopdracht, tenzij er ernstige feiten en omstandigheden aan de opzegging ten grondslag liggen\n" +
        " die aan Opdrachtnemer zijn toe te rekenen.",
      "Opdrachtgever is tegenover derden aansprakelijk voor de gevolgen van de opzegging en vrijwaart\n" +
        " Opdrachtnemer ter zake.",
      "Indien tot (tussentijdse) opzegging is overgegaan door Opdrachtnemer, heeft Opdrachtgever recht op\n" +
        " medewerking van Opdrachtnemer bij overdracht van werkzaamheden aan derden, tenzij er feiten en\n" +
        " omstandigheden aan die opzegging ten grondslag liggen die aan Opdrachtgever zijn toe te rekenen.\n" +
        " Opdrachtnemer behoudt in alle gevallen van (tussentijdse) opzegging aanspraak op betaling van de\n" +
        " declaraties voor door haar tot dan toe verrichte werkzaamheden.",
    ],
  },
  {
    title: "Artikel 12 Overmacht",
    content: [
      "Onder overmacht wordt in deze algemene voorwaarden verstaan: een niet-toerekenbare tekortkoming,\n" +
        " als gedefinieerd in artikel 6:75 Burgerlijk Wetboek, aan de zijde van Opdrachtnemer en de door haar\n" +
        " ingeschakelde derden. ",
      "Ingeval er sprake is van overmacht is Opdrachtnemer gerechtigd de overeenkomst te ontbinden dan wel\n" +
        " de nakoming van haar verplichtingen jegens Opdrachtgever voor een redelijke termijn op te schorten\n" +
        " zonder tot enige schadevergoeding gehouden te zijn.",
      "Ingeval sprake is van overmacht als de overeenkomst ten dele is uitgevoerd, is Opdrachtgever\n" +
        " gehouden zijn verplichtingen tot aan dat moment na te komen.",
    ],
  },
  {
    title: "Artikel 13 Aansprakelijkheid",
    content: [
      "Opdrachtnemer zal haar werkzaamheden deskundig verrichten en daarbij de zorgvuldigheid in acht\n" +
        " nemen die van Opdrachtnemer kan worden verwacht. Indien direct of indirect schade ontstaat doordat\n" +
        " Opdrachtgever aan Opdrachtnemer onjuiste of onvolledige informatie heeft verstrekt, is Opdrachtnemer\n" +
        " daarvoor niet aansprakelijk. Opdrachtnemer is eveneens niet aansprakelijk indien Opdrachtgever niet\n" +
        " handelt conform de door Opdrachtnemer gegeven instructies en adviezen. ",
      "Indien Opdrachtgever aantoont dat zij schade heeft geleden die bij zorgvuldig handelen door\n" +
        " Opdrachtnemer zou zijn vermeden, is Opdrachtnemer voor die schade aansprakelijk tot het bedrag of\n" +
        " de bedragen die tot het moment van aantonen van de schade, door Opdrachtgever aan de\n" +
        " Opdrachtnemer zijn betaald in verband met de werkzaamheden. ",
      "Indien door Opdrachtnemer een aansprakelijkheidsverzekering is afgesloten geldt in aanvulling op\n" +
        " lid 13.2 dat de aansprakelijkheid van Opdrachtnemer beperkt wordt tot maximaal het bedrag waarop de\n" +
        " door Opdrachtnemer afgesloten aansprakelijkheidsverzekering aanspraak geeft met inbegrip van het\n" +
        " eigen risico dat de Opdrachtnemer in verband met die verzekering draagt.",
      "De in lid 13.1 t/m 13.3 van dit artikel neergelegde aansprakelijkheidsbeperkingen worden mede\n" +
        " bedongen ten behoeve van de door Opdrachtnemer voor de uitvoering van de overeenkomst\n" +
        " ingeschakelde derden.",
      "Opdrachtnemer is nimmer aansprakelijk voor schade van Opdrachtgever die door ingeschakelde derden is\n" +
        " veroorzaakt. ",
      "Opdrachtnemer is nimmer aansprakelijk voor schade aan de verhuurde (woon) ruimte – al dan niet\n" +
        " veroorzaakt door huurder. Het is aan Opdrachtgever zelf de (woon)ruimte periodiek te controleren op\n" +
        " eventuele schade en/of gebreken en zo nodig de huurder daarop aan te spreken.",
      "Opdrachtnemer bemiddelt bij het tot stand komen van een huurovereenkomst tussen Opdrachtgever en\n" +
        " potentiële huurder(s), doch is geen partij in deze. Opdrachtnemer is niet aansprakelijk voor de inhoud en\n" +
        " uitvoering van de huurovereenkomst, waaronder begrepen het mogelijk in strijd zijn met wettelijke regels\n" +
        " ten aanzien van de hoogte van de overeengekomen huurprijs en servicekosten. ",
      "Tijdens de uitvoering van de overeenkomst zullen Opdrachtgever en Opdrachtnemer op verzoek van één\n" +
        " van hen door middel van elektronische mail met elkaar communiceren. Zowel Opdrachtnemer als\n" +
        " Opdrachtgever erkennen dat aan het gebruik van elektronische mail risico’s kleven zoals, maar niet\n" +
        " beperkt tot, vervorming, vertraging en virus. Opdrachtgever en Opdrachtnemer zijn jegens elkaar niet\n" +
        " aansprakelijk voor schade die ontstaat bij één of ieder van hen ten gevolge van het gebruik van\n" +
        " elektronische mail. Zowel Opdrachtgever als Opdrachtnemer zullen al hetgeen redelijkerwijs van ieder\n" +
        " van hen verwacht mag worden doen of nalaten ter voorkoming van het optreden van voornoemde\n" +
        " risico’s. ",
      "Alle Reclamemateriaal, brochures en/of op de website van Opdrachtnemer getoonde en/of verstrekte\n" +
        " voorbeelden zijn zo nauwkeurig mogelijk opgesteld, maar gelden slechts ter aanduiding en het\n" +
        " verstrekken van informatie. Hieraan kunnen geen rechten of aanspraken worden ontleend, tenzij partijen\n" +
        " uitdrukkelijk schriftelijk anders zijn overeengekomen.",
    ],
  },
  {
    title: "Artikel 14 Toepasselijk recht en forumkeuze",
    content: [
      "Op alle overeenkomsten tussen Opdrachtnemer en Opdrachtgever is Nederlands recht van toepassing. ",
      "Tenzij Opdrachtnemer en Opdrachtgever uitdrukkelijk schriftelijk anders overeenkomen, worden alle\n" +
        " geschillen die verband houden met overeenkomsten tussen Opdrachtgever en Opdrachtnemer, door de\n" +
        " bevoegde rechter beslecht in het arrondissement Amsterdam.",
    ],
  },
];
const algemene_voorwaarden_bregman = [
  {
    title: "Artikel 1 Definities en toepasselijkheid",
    content: [
      "Deze algemene voorwaarden zijn van toepassing op alle overeenkomsten die Bregman Vastgoedbeheer B.V., hierna te " +
        "noemen ‘Opdrachtnemer’, sluit met haar Opdrachtgevers in de hoedanigheid van verhuurder, hierna te noemen " +
        "‘Opdrachtgever’, eveneens zijn deze algemene voorwaarden van toepassing op alle dienstverlening en overige " +
        "(rechts) handelingen tussen Opdrachtnemer en Opdrachtgever.",
      "Deze algemene voorwaarden zijn ook van toepassing op alle dienstverlening door Opdrachtnemer aan\n" +
        "Opdrachtgever voor de uitvoering waarvan derden worden betrokken. Onder derden worden mede\n" +
        "verstaan vennootschappen behorend tot het concern waartoe e Bregman Vastgoedbeheer B.V. behoort.",
      "Onder ‘overeenkomst’ wordt in deze algemene voorwaarden de bemiddelingsopdracht tot beheer, hierna te noemen " +
        "‘Beheeropdracht’, verstaan, die tot stand komt tussen Opdrachtgever en Opdrachtnemer, waarbij Opdrachtnemer een " +
        "door Opdrachtgever verhuurde (woon-)ruimte in beheer neemt.",
      "Onder ‘schriftelijk’ wordt in deze algemene voorwaarden eveneens verstaan: per e-mail, per fax of enige andere " +
        "wijze van communicatie die met het oog op de stand der techniek en de in het maatschappelijk verkeer geldende " +
        "opvattingen hiermee gelijk kan worden gesteld.\n",
      "Het om welke reden dan ook niet van toepassing zijn van een (deel van een) bepaling van deze algemene voorwaarden " +
        "laat de toepasselijkheid van de overige bepalingen onverlet.\n",
      "Opdrachtnemer heeft het recht - al dan niet bij gewijzigde regelgeving - deze algemene voorwaarden aan\n" +
        "te passen.",
      "Opdrachtgever kan zich niet beroepen op het feit dat de algemene voorwaarden niet ter hand zijn gesteld,\n" +
        "indien Opdrachtnemer deze algemene voorwaarden al eerder bij een andere transactie aan Opdrachtgever\n" +
        "heeft verstrekt. ",
      "De toepasselijkheid van algemene voorwaarden van Opdrachtgever wordt door Opdrachtnemer\n" +
        "uitdrukkelijk van de hand gewezen. ",
    ],
  },
  {
    title: "Artikel 2 Aanbiedingen, offertes",
    content: [
      "Alle aanbiedingen, offertes van Opdrachtnemer zijn vrijblijvend, tenzij ze zijn opgenomen in een door " +
        "Opdrachtnemer opgestelde en rechtsgeldig ondertekende Beheeropdracht.",
    ],
  },
  {
    title: "Artikel 3 Overeenkomst, opdracht",
    content: [
      "De overeenkomst komt tot stand op het moment dat de door Opdrachtnemer en Opdrachtgever\n" +
        " ondertekende Beheeropdracht door Opdrachtnemer retour is ontvangen. De overeenkomst\n" +
        " is gebaseerd op ten tijde daarvan door de Opdrachtgever aan Opdrachtnemer verstrekte informatie. De\n" +
        " overeenkomst wordt geacht juist en volledig te zijn weer gegeven. ",
      "Opdrachtnemer zal zich naar beste kunnen en weten inspannen om het door Opdrachtgever gewenste\n" +
        " c.q. beoogde resultaat te bereiken. Dit is te allen tijde een inspanningsverplichting van Opdrachtnemer en\n" +
        " geen resultaatsverplichting. Als voornoemd resultaat uitblijft, ontslaat dit Opdrachtgever derhalve niet\n" +
        " van zijn verplichtingen jegens Opdrachtnemer, met uitzondering van eventuele verplichtingen die door\n" +
        " partijen uitdrukkelijk gekoppeld zijn aan het bereiken van een beoogde resultaat.",
      "Indien de overeenkomst mondeling is verstrekt, dan wel (nog) niet getekend retour is ontvangen\n" +
        " wordt de overeenkomst geacht te zijn tot stand gekomen onder toepasselijkheid van deze\n" +
        " algemene voorwaarden op het moment dat Opdrachtnemer op verzoek van Opdrachtgever met de\n" +
        " uitvoering van de overeenkomst is gestart. \n",
      " De overeenkomst komt in plaats van, en vervangt, alle eerdere voorstellen, correspondentie, afspraken of\n" +
        " andere communicatie schriftelijk dan wel mondeling gedaan.",
      "De door de Opdrachtgever, na het aangaan van de overeenkomst, alsnog gewenste wijzigingen\n" +
        " in de uitvoering daarvan, nadere instructies en overige mededelingen met betrekking tot de\n" +
        " overeenkomst, moeten door de Opdrachtgever tijdig en schriftelijk aan Opdrachtnemer ter kennis\n" +
        " worden gebracht.",
      "Aanvullingen of wijzigingen op de algemene voorwaarden of anderszins wijzigingen of aanvullingen op de\n" +
        " overeenkomst worden eerst na schriftelijke bevestiging door Opdrachtnemer bindend.",
      "Wijzigingen in de oorspronkelijke overeenkomst, van welke aard ook, door of namens de\n" +
        "Opdrachtgever aangebracht, die hogere kosten veroorzaken dan waarmee in de oorspronkelijke\n" +
        "overeenkomst rekening kon worden gehouden, worden aan de Opdrachtgever als meerwerk in rekening\n" +
        "gebracht.",
      "De overeenkomst wordt aangegaan voor onbepaalde tijd, tenzij uit de inhoud, aard of strekking van de\n" +
        " verleende opdracht voortvloeit dat deze voor een bepaalde tijd is aangegaan. ",
      "Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft Opdrachtnemer het\n" +
        " recht bepaalde werkzaamheden / leveringen door derden te laten verrichten. Een en ander ter\n" +
        " beoordeling van Opdrachtnemer.",
    ],
  },
  {
    title: "Artikel 4 Uitvoering van de overeenkomst",
    content: [
      "Alle werkzaamheden die door Opdrachtnemer worden verricht, worden zorgvuldig en op deskundig wijze\n" +
        " uitgevoerd door Opdrachtnemer overeenkomstig de hiervoor in haar branche geldende maatstaven. \n",
      "Ten aanzien van de beoogde werkzaamheden is nadrukkelijk sprake van een inspanningsverbintenis aan de\n" +
        " zijde van de Opdrachtnemer, tenzij uitdrukkelijk anders is bepaald.",
      "Opdrachtnemer bepaalt de wijze waarop de overeenkomst wordt uitgevoerd, doch\n" +
        " neemt daarbij de door Opdrachtgever kenbaar gemaakte eisen zoveel mogelijk in acht.",
      " Opdrachtnemer kan eerst meer werkzaamheden (laten) verrichten en aan Opdrachtgever in rekening\n" +
        " brengen dan waartoe de Beheeropdracht is verstrekt, indien Opdrachtgever hiervoor vooraf toestemming\n" +
        " heeft verleend. Indien Opdrachtnemer echter uit hoofde van zijn wettelijke zorgplicht gehouden is om\n" +
        " meerwerk te verrichten is hij gerechtigd dit aan Opdrachtgever in rekening te brengen, ook indien\n" +
        " Opdrachtgever voor het verrichten van het meerwerk vooraf niet expliciet zijn toestemming heeft\n" +
        " verleend.",
      "Opdrachtnemer kan niet eerder verplicht worden met de uitvoering van de werkzaamheden te beginnen,\n" +
        " dan nadat alle daarvoor noodzakelijk gegevens in haar bezit zijn en zij de eventueel overeengekomen\n" +
        " (vooruit)betaling heeft ontvangen.",
      "Opgegeven termijnen waarbinnen door Opdrachtnemer de werkzaamheden moeten zijn verricht c.q. de\n" +
        " bescheiden en/of diensten moeten zijn geleverd kunnen nimmer worden beschouwd als fatale termijnen.\n" +
        " Omdat de duur van de overeenkomst kan worden beïnvloed door allerlei factoren, zoals de\n" +
        " kwaliteit van de informatie die Opdrachtgever verstrekt en de medewerking die wordt verleend, zijn de\n" +
        " termijnen waarbinnen de werkzaamheden dienen te zijn afgerond, slechts te beschouwen als fatale\n" +
        " termijnen indien dit uitdrukkelijk schriftelijk is overeengekomen. ",
      "Indien Opdrachtnemer haar verplichtingen uit de overeenkomst niet dan wel niet tijdig nakomt, dient zij\n" +
        " schriftelijk in gebreke te worden gesteld.",
    ],
  },
  {
    title: "Artikel 5 Verplichtingen Opdrachtgever",
    content: [
      "Opdrachtgever dient ervoor zorg te dragen dat alle gegevens en bescheiden, welke Opdrachtnemer\n" +
        " overeenkomstig zijn oordeel nodig heeft voor het correct en tijdig uitvoeren van de overeenkomst, tijdig\n" +
        " en in de door Opdrachtnemer gewenste vorm en wijze aan Opdrachtnemer ter beschikking worden\n" +
        " gesteld. ",
      "Tenzij uit de aard van de overeenkomst anders voortvloeit is Opdrachtgever verantwoordelijk voor\n" +
        " de juistheid, volledigheid en betrouwbaarheid van de aan Opdrachtnemer ter beschikking gestelde\n" +
        " gegevens en bescheiden, ook indien deze via of van derden afkomstig zijn. Opdrachtgever garandeert\n" +
        " tevens dat alle aangeleverde gegevens en bescheiden rechtmatig zijn verkregen en dat hiermee geen\n" +
        " inbreuk wordt gemaakt op de rechten van derden. Opdrachtgever vrijwaart Opdrachtnemer voor gevolgen\n" +
        " voortvloeiende uit het niet juist en/of onvolledig of onrechtmatig verkregen zijn van de gegevens",
      "Opdrachtgever dient ervoor zorg te dragen dat Opdrachtnemer onverwijld wordt geïnformeerd over feiten\n" +
        " en omstandigheden die in verband met de correcte uitvoering van de overeenkomst van belang\n" +
        " kunnen zijn. ",
      "Indien niet tijdig aan de in dit artikel genoemde verplichtingen is voldaan, is Opdrachtnemer gerechtigd de\n" +
        " uitvoering van de overeenkomst op te schorten tot het moment dat de Opdrachtgever wel aan deze\n" +
        " verplichtingen heeft voldaan.",
      "Opdrachtgever is aansprakelijk voor eventuele aanspraken van welke aard dan ook, welke kunnen\n" +
        " voorvloeien uit de door de Opdrachtgever – onder bemiddeling door Opdrachtnemer - met de derden in\n" +
        " het kader van het beheer gesloten overeenkomsten. Opdrachtgever vrijwaart Opdrachtnemer uitdrukkelijk\n" +
        " voor dergelijke aanspraken.",
      "Het is Opdrachtgever verboden informatie, die hij ter zake van de overeenkomst van Opdrachtnemer\n" +
        " ontvangt, zonder voorafgaande toestemming van Opdrachtnemer, aan derden door te geven, zulks op\n" +
        " straffe van vergoeding van alle schade, die hierdoor voor Opdrachtnemer zal ontstaan.",
      "Het is Opdrachtgever niet toegestaan verplichtingen uit de overeenkomst over te dragen aan derden.\n" +
        " Opdrachtgever blijft te allen tijde aansprakelijk voor de verplichtingen, tenzij Opdrachtnemer en\n" +
        " Opdrachtgever expliciet anders overeenkomen. ",
      "Opdrachtgever staat er voor in dat Opdrachtnemer onbeperkt toegang heeft / krijgt tot het te verhuren\n" +
        " onroerend goed om de (technische) staat ervan te kunnen inspecteren.",
    ],
  },
  {
    title: "Artikel 6 Geheimhouding",
    content: [
      "Tenzij enige wetsbepaling, voorschrift of andere (beroeps)regel haar daartoe verplicht, is Opdrachtnemer\n" +
        " verplicht tot geheimhouding tegenover derden ten aanzien van vertrouwelijke informatie die is verkregen\n" +
        " van Opdrachtgever. Opdrachtgever kan ter zake ontheffing verlenen. \n",
      " Behoudens schriftelijke toestemming van Opdrachtgever is Opdrachtnemer gerechtigd de vertrouwelijke\n" +
        " informatie die aan haar door Opdrachtgever ter beschikking wordt gesteld niet aan te wenden tot een\n" +
        " ander doel dan waarvoor zij werd verkregen. Hiertoe wordt echter een uitzondering gemaakt, in het geval\n" +
        " Opdrachtnemer voor zichzelf optreedt in een tucht-, civiele- of strafprocedure waarbij deze van belang kan\n" +
        " zijn.",
      "Tenzij sprake is van enige wetsbepaling, voorschrift of andere (beroeps)regel die Opdrachtgever verplicht\n" +
        " tot openbaarmaking of daartoe door Opdrachtnemer voorafgaande schriftelijke toestemming is verleend,\n" +
        " zal opdrachtgever de inhoud van rapporten, adviezen of andere al dan niet schriftelijke uitingen van de\n" +
        " Opdrachtnemer, niet aan derden openbaren, De Opdrachtgever zal er tevens voor zorg dragen dat derden\n" +
        " niet van de in de vorige zin bedoelde inhoud kennis kunnen nemen. \n",
      "Opdrachtgever en Opdrachtnemer zullen hun verplichtingen op grond van dit artikel opleggen aan door\n" +
        " hen in te schakelen derden. \n",
      "Als niet in strijd geacht zijnde met het bepaalde in artikel 6.1 en 6.4, is Opdrachtnemer gerechtigd tot het\n" +
        " vermelden in hoofdlijnen van de verrichte werkzaamheden aan (potentiële) klanten van Opdrachtnemer\n" +
        " en slechts ter indicatie van de ervaring van Opdrachtnemer.",
    ],
  },
  {
    title: "Artikel 7 Intellectuele eigendom",
    content: [
      "Opdrachtnemer behoudt zich alle rechten van intellectuele eigendom voor met betrekking tot producten\n" +
        " van de geest welke hij gebruikt of heeft gebruikt en/of ontwikkelt en/of heeft ontwikkeld, en ten aanzien\n" +
        " waarvan hij de auteursrechten of andere rechten van intellectuele eigendom heeft of geldend kan maken. ",
      "Het is de Opdrachtgever uitdrukkelijk verboden die producten, waaronder mede begrepen\n" +
        " computerprogramma’s, systeemontwerpen, werkwijzen, adviezen, (model)contracten en andere\n" +
        " geestesproducten van de Opdrachtnemer, een en ander in de ruimste zijn des woords, al dan niet met\n" +
        " inschakeling van derden te verveelvoudigen, te openbaren of te exploiteren. Verveelvoudiging en/of\n" +
        " openbaarmaking en/of exploitatie is slechts na uitdrukkelijk verkregen schriftelijke toestemming van\n" +
        " Opdrachtnemer toegestaan. Opdrachtgever heeft het recht de schriftelijke documenten te\n" +
        " vermenigvuldigen voor gebruik binnen zijn eigen organisatie voor zover passend binnen het doel van de\n" +
        " opdracht. Ingeval van tussentijdse beëindiging van de opdracht, is het voorgaande van overeenkomstige\n" +
        " toepassing. ",
    ],
  },
  {
    title: "Artikel 8. Bemiddelingsvergoeding, kosten en declaraties",
    content: [
      "Opdrachtnemer en Opdrachtgever komen in de Beheeropdracht de hoogte van de beheervergoeding voor Opdrachtnemer " +
        "overeen. Naast de beheervergoeding is de Opdrachtnemer gerechtigd onkosten en kosten van door Opdrachtnemer " +
        "ingeschakelde derden in rekening te brengen bij Opdrachtgever. ",
      "Alle tarieven luiden in Euro’s en zijn exclusief omzetbelasting en andere heffingen welke van\n" +
        " overheidswege (kunnen) worden opgelegd.",
      "De bemiddelingsvergoeding van Opdrachtnemer, zo nodig vermeerderd met gedeclareerde kosten van\n" +
        " ingeschakelde derden, wordt per maand aan de Opdrachtgever in rekening gebracht, tenzij de\n" +
        " Opdrachtgever en Opdrachtnemer hierover andere afspraken hebben gemaakt.",
      "De uit de vertraging in de uitvoering van de overeenkomst voortvloeiende kosten, ontstaan door het niet,\n" +
        " niet tijdig of niet behoorlijk ter beschikking stellen van de verzochte gegevens, bescheiden en toegang tot\n" +
        " het verhuurd object zijn voor rekening van de Opdrachtgever. ",
      " Alle onkosten welke door Opdrachtnemer worden gemaakt op verzoek van Opdrachtgever komen geheel\n" +
        " voor rekening van Opdrachtgever, tenzij partijen uitdrukkelijk schriftelijk anders zijn overeengekomen.",
    ],
  },
  {
    title: "Artikel 9 Betaling",
    content: [
      "Opdrachtnemer is bevoegd om - ter zake de nakoming van financiële verplichtingen van Opdrachtgever –\n" +
        " vooruitbetaling of zekerheid van Opdrachtgever te verlangen, alvorens met de te verrichten\n" +
        " werkzaamheden te starten dan wel deze voort te zetten.",
      "Betaling dient door Opdrachtgever zonder aftrek, korting of schuldverrekening te geschieden binnen 14\n" +
        " dagen na factuurdatum. Deze termijn geldt als fatale termijn. Betaling dient te geschieden in op de factuur\n" +
        " aangegeven valuta, door middel van overmaking ten gunste van een door Opdrachtnemer aan te wijzen\n" +
        " bankrekening. Bezwaren tegen de hoogte van de ingediende facturen schorten de betalingsverplichting\n" +
        " van Opdrachtgever niet op. ",
      "Bij overschrijding van de onder 9.2 genoemde termijn, is Opdrachtgever, na door Opdrachtnemer ten\n" +
        " minste éénmaal te zijn aangemaand om binnen een redelijke termijn te betalen, van rechtswege in\n" +
        " verzuim. In dat geval is Opdrachtgever, vanaf de datum waarop de verschuldigde som opeisbaar is\n" +
        " geworden tot aan het tijdstip van betaling over het verschuldigde wettelijke rente verschuldigd.",
      "Alle kosten van invordering komen, nadat Opdrachtgever in verzuim is, zowel gerechtelijk als\n" +
        " buitengerechtelijk, ten laste van Opdrachtgever. De buitengerechtelijke kosten worden vastgesteld op ten\n" +
        " minste 15% van de hoofdsom en rente, onverminderd het recht van Opdrachtnemer de werkelijke\n" +
        " buitengerechtelijke kosten die dit bedrag te boven gaan, te vorderen. De gerechtelijke kosten omvatten de\n" +
        " volledige door Opdrachtnemer gemaakte kosten, ook als deze het wettelijke liquidatietarief te boven gaan.",
      "Opdrachtnemer heeft het recht, zonder nadere ingebrekestelling of rechterlijke tussenkomst, de\n" +
        " overeenkomst geheel of gedeeltelijk te ontbinden als Opdrachtgever van rechtswege in verzuim is. Dit laat\n" +
        " het recht op betaling door Opdrachtgever onverlet.",
    ],
  },
  {
    title: "Artikel 10 Reclames",
    content: [
      "Opdrachtgever is verplicht direct na ontvangst van de Beheeropdracht en overige bescheiden, over te\n" +
        " gaan tot controle hiervan. Eventuele zichtbare fouten of onvolkomenheden dienen uiterlijk binnen 2\n" +
        " werkdagen na ontvangst schriftelijk te worden gemeld aan Opdrachtnemer.",
      "Reclames met betrekking tot de verrichte werkzaamheden dienen door Opdrachtgever schriftelijk binnen\n" +
        " 30 dagen na uitvoering van de werkzaamheden aan Opdrachtnemer kenbaar te worden gemaakt. ",
      "Reclames als in het eerste lid bedoeld, schorten de betalingsverplichting van Opdrachtgever niet op. ",
    ],
  },
  {
    title: "Artikel 11 Opzegging",
    content: [
      "Opdrachtgever en Opdrachtnemer kunnen de overeenkomst te allen tijde (tussentijds) per aangetekend\n" +
        " schrijven opzeggen met inachtneming van een opzegtermijn van 3 maanden, tenzij de redelijkheid en\n" +
        " billijkheid zich tegen beëindiging of beëindiging op een dergelijke termijn verzetten.",
      "De overeenkomst mag door zowel Opdrachtgever als Opdrachtnemer per aangetekend schrijven\n" +
        " (tussentijds) worden opgezegd zonder inachtneming van een opzegtermijn in het geval de andere partij\n" +
        " niet in staat is om haar schulden te voldoen of indien een curator, bewindvoerder of vereffenaar is\n" +
        " benoemd, de andere partij een schuldsanering treft, of om enige andere reden haar activiteiten staakt of\n" +
        " indien de andere partij het ontstaan van een van de bovengenoemde omstandigheden bij de ene partij\n" +
        " redelijkerwijs aannemelijk acht of inden er een situatie is ontstaan die onmiddellijke beëindiging\n" +
        " rechtvaardigt in het belang van de opzeggende partij. ",
      "Indien tot (tussentijdse) opzegging is overgegaan door Opdrachtgever, heeft Opdrachtnemer recht op\n" +
        " vergoeding van de reeds door haar verrichte werkzaamheden en gemaakte onkosten, alsmede van\n" +
        " additionele kosten die zij redelijkerwijs moet maken ten gevolge van de vroegtijdige beëindiging van de\n" +
        " Beheeropdracht, tenzij er ernstige feiten en omstandigheden aan de opzegging ten grondslag liggen\n" +
        " die aan Opdrachtnemer zijn toe te rekenen.",
      "Opdrachtgever is tegenover derden aansprakelijk voor de gevolgen van de opzegging en vrijwaart\n" +
        " Opdrachtnemer ter zake.",
      "Indien tot (tussentijdse) opzegging is overgegaan door Opdrachtnemer, heeft Opdrachtgever recht op\n" +
        " medewerking van Opdrachtnemer bij overdracht van werkzaamheden aan derden, tenzij er feiten en\n" +
        " omstandigheden aan die opzegging ten grondslag liggen die aan Opdrachtgever zijn toe te rekenen.\n" +
        " Opdrachtnemer behoudt in alle gevallen van (tussentijdse) opzegging aanspraak op betaling van de\n" +
        " declaraties voor door haar of door haar ingeschakelde derden tot dan toe verrichte werkzaamheden.",
    ],
  },
  {
    title: "Artikel 12 Overmacht",
    content: [
      "Onder overmacht wordt in deze algemene voorwaarden verstaan: een niet-toerekenbare tekortkoming,\n" +
        " als gedefinieerd in artikel 6:75 Burgerlijk Wetboek, aan de zijde van Opdrachtnemer en de door haar\n" +
        " ingeschakelde derden. ",
      "Ingeval er sprake is van overmacht is Opdrachtnemer gerechtigd de overeenkomst te ontbinden dan wel\n" +
        " de nakoming van haar verplichtingen jegens Opdrachtgever voor een redelijke termijn op te schorten\n" +
        " zonder tot enige schadevergoeding gehouden te zijn.",
      "Ingeval sprake is van overmacht als de overeenkomst ten dele is uitgevoerd, is Opdrachtgever\n" +
        " Gehouden zijn verplichtingen tot aan dat moment na te komen.",
    ],
  },
  {
    title: "Artikel 13 Aansprakelijkheid",
    content: [
      "Opdrachtnemer zal haar werkzaamheden deskundig verrichten en daarbij de zorgvuldigheid in acht\n" +
        " nemen die van Opdrachtnemer kan worden verwacht. Indien direct of indirect schade ontstaat doordat\n" +
        " Opdrachtgever aan Opdrachtnemer onjuiste of onvolledige informatie heeft verstrekt, is Opdrachtnemer\n" +
        " daarvoor niet aansprakelijk. Opdrachtnemer is eveneens niet aansprakelijk indien Opdrachtgever niet\n" +
        " handelt conform de door Opdrachtnemer gegeven instructies en adviezen. ",
      "Indien Opdrachtgever aantoont dat zij schade heeft geleden die bij zorgvuldig handelen door\n" +
        " Opdrachtnemer zou zijn vermeden, is Opdrachtnemer voor die schade aansprakelijk tot het bedrag van\n" +
        " maximaal Opdrachtgever aan Opdrachtnemer betaalde beheervergoeding in de afgelopen 3 maanden\n" +
        " voorafgaande aan de schade.",
      "Indien door Opdrachtnemer een aansprakelijkheidsverzekering is afgesloten geldt in aanvulling op\n" +
        " lid 13.2 dat de aansprakelijkheid van Opdrachtnemer beperkt wordt tot maximaal het bedrag waarop de\n" +
        " door Opdrachtnemer afgesloten aansprakelijkheidsverzekering aanspraak geeft met inbegrip van het\n" +
        " eigen risico dat de Opdrachtnemer in verband met die verzekering draagt. ",
      "De in lid 13.1 t/m 13.3 van dit artikel neergelegde aansprakelijkheidsbeperkingen worden mede\n" +
        " bedongen ten behoeve van de door Opdrachtnemer voor de uitvoering van de overeenkomst\n" +
        " ingeschakelde derden. ",
      "Opdrachtnemer is nimmer aansprakelijk voor schade van Opdrachtgever die door ingeschakelde derden is\n" +
        " veroorzaakt. ",
      "Opdrachtnemer is nimmer aansprakelijk voor schade aan de verhuurde (woon) ruimte – al dan niet\n" +
        " veroorzaakt door huurder of door Opdrachtnemer ingeschakelde derden. Het is aan Opdrachtgever zelf\n" +
        " de (woon)ruimte periodiek te controleren op eventuele schade en/of gebreken.",
      "Tijdens de uitvoering van de overeenkomst zullen Opdrachtgever en Opdrachtnemer op verzoek van één\n" +
        " van hen door middel van elektronische mail met elkaar communiceren, zowel Opdrachtnemer als\n" +
        " Opdrachtgever erkennen dat aan het gebruik van elektronische mail risico’s kleven zoals, maar niet\n" +
        " beperkt tot, vervorming, vertraging en virus. Opdrachtgever en Opdrachtnemer zijn egens elkaar niet\n" +
        " aansprakelijk voor schade die voortvloeit bij één of ieder van hen ten gevolge van het gebruik van\n" +
        " elektronische mail. Zowel Opdrachtgever als Opdrachtnemer zullen al hetgeen redelijkerwijs van ieder\n" +
        " van hen verwacht mag worden doen of nalaten ter voorkoming van het optreden van voornoemde\n" +
        " risico’s. ",
      "Alle Reclamemateriaal, brochures en / of op de website van Opdrachtnemer getoonde en/of verstrekte\n" +
        " voorbeelden zijn zo nauwkeurig mogelijk opgesteld, maar gelden slechts ter aanduiding en het\n" +
        " verstrekken van informatie. Hieraan kunnen geen rechten of aanspraken worden ontleend, tenzij partijen\n" +
        " uitdrukkelijk schriftelijk anders zijn overeengekomen.",
    ],
  },
  {
    title: "Artikel 14 Toepasselijk recht en forumkeuze",
    content: [
      "Op alle overeenkomsten tussen Opdrachtnemer en Opdrachtgever is Nederlands recht van toepassing. ",
      "Tenzij Opdrachtnemer en Opdrachtgever uitdrukkelijk schriftelijk anders overeenkomen, worden alle\n" +
        " geschillen die verband houden met overeenkomsten tussen Opdrachtgever en Opdrachtnemer, door de\n" +
        " bevoegde rechter beslecht in het arrondissement Amsterdam.",
    ],
  },
];

function Terms() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Algemene voorwaarden: Vastgoed Verhuurmakelaardij
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Laatst gewijzigd: 20 Juni 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6}>
                    Algemene voorwaarden Vastgoed Verhuurmakelaardij B.V.
                  </MKTypography>
                  <MKTypography variant="h6" mb={3}>
                    Ingeschreven bij de Kamer van Koophandel onder nummer 85158984
                  </MKTypography>

                  {algemene_voorwaarden.map(({ title, content }, i) => (
                    <Box key={i} mb={3}>
                      <MKTypography variant="h5" mb={1}>
                        {title}
                      </MKTypography>
                      {content.map((text, ii) => (
                        <MKTypography variant="body2" color="text" fontWeight="regular" key={ii}>
                          {i + 1}.{ii + 1} {text}
                        </MKTypography>
                      ))}
                    </Box>
                  ))}
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12} mt={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Algemene voorwaarden: Bregman Vastgoedbeheer
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Laatst gewijzigd: 20 Juni 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6}>
                    Algemene voorwaarden Bregman Vastgoedbeheer B.V.
                  </MKTypography>
                  <MKTypography variant="h6" mb={3}>
                    Ingeschreven bij de Kamer van Koophandel onder nummer 85158739
                  </MKTypography>

                  {algemene_voorwaarden_bregman.map(({ title, content }, i) => (
                    <Box key={i} mb={3}>
                      <MKTypography variant="h5" mb={1}>
                        {title}
                      </MKTypography>
                      {content.map((text, ii) => (
                        <MKTypography variant="body2" color="text" fontWeight="regular" key={ii}>
                          {i + 1}.{ii + 1} {text}
                        </MKTypography>
                      ))}
                    </Box>
                  ))}
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Terms;
