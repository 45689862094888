/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/vastgoedverhuurmakelaardij/homepage-cover.webp";
import Socials from "../../../examples/Socials";
import NewOffersBanner from "../../../examples/NewOffersBanner";
import CallToAction from "../../../examples/CallToAction";
import ContactForm from "../../../examples/ContactForms/ContactForm";
import PersonalInformation from "./sections/PersonalInformation";
import Features from "./sections/Features";
import Testimonials from "../../../examples/Testimonials";

function AboutUs() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <MKBox
        ref={headerRef}
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            {/* <MKTypography variant="h1" color="white"> */}
            {/*   Vastgoed Verhuurmakelaardij */}
            {/* </MKTypography> */}
            {/* <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}> */}
            {/*   Maak kennis met Vastgoed Verhuurmakelaardij en de oprichter Matthijs Bregman. */}
            {/* </MKTypography> */}
            {/* <MKTypography variant="h6" color="white" mt={8} mb={1}> */}
            {/*   Vind ons op */}
            {/* </MKTypography> */}
            {/* <MKBox display="flex" justifyContent="center" alignItems="center"> */}
            {/*   <MKTypography */}
            {/*     component="a" */}
            {/*     variant="body1" */}
            {/*     color="white" */}
            {/*     href="https://www.facebook.com/VastgoedVerhuurmakelaardij" */}
            {/*     mr={3} */}
            {/*     target="_blank" */}
            {/*   > */}
            {/*     <i className="fab fa-facebook" /> */}
            {/*   </MKTypography> */}
            {/*   <MKTypography */}
            {/*     component="a" */}
            {/*     variant="body1" */}
            {/*     color="white" */}
            {/*     href="https://www.instagram.com/vastgoedverhuurmakelaardij" */}
            {/*     mr={3} */}
            {/*     target="_blank" */}
            {/*   > */}
            {/*     <i className="fab fa-instagram" /> */}
            {/*   </MKTypography> */}
            {/*   <MKTypography */}
            {/*     component="a" */}
            {/*     variant="body1" */}
            {/*     color="white" */}
            {/*     href="https://www.linkedin.com/company/vastgoed-verhuurmakelaardij" */}
            {/*     target="_blank" */}
            {/*   > */}
            {/*     <i className="fab fa-linkedin" /> */}
            {/*   </MKTypography> */}
            {/* </MKBox> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Features />
        <PersonalInformation />
        <Testimonials />
        <ContactForm />
        <CallToAction />
        <NewOffersBanner />
        <Socials />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
