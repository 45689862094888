import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import MKBox from "../../../components/MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import MKButton from "../../../components/MKButton";
import MKAlert from "../../../components/MKAlert";
import ReCAPTCHA from "react-google-recaptcha";

function ContactFormRenters() {
  const form = useRef();
  const [checked, setChecked] = useState(false);
  const handleChecked = () => setChecked(!checked);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setShowSuccess(false);
    setShowError(false);

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACT_RENTERS_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowSuccess(true);
          window.scrollTo(0, 0);
        },
        (error) => {
          setShowError(true);
          window.scrollTo(0, 0);
        }
      );
  };

  return (
    <MKBox component="section" py={12}>
      <Container>
        {showSuccess && <MKAlert color="success">Je formulier is verzonden!</MKAlert>}
        {showError && (
          <MKAlert color="error">Er is iets fout gegaan, probeer het later opnieuw</MKAlert>
        )}
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          <MKTypography variant="h3" mb={1}>
            Neem contact met ons op om te verhuren
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
          <MKBox
            width="100%"
            component="form"
            method="post"
            autocomplete="off"
            ref={form}
            onSubmit={sendEmail}
          >
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} mt={6}>
                  <MKTypography item variant="p">
                    Eigen informatie
                  </MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput variant="standard" label="Voornaam" name="firstname" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput variant="standard" label="Achternaam" name="lastname" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <MKInput variant="standard" label="Telefoon" name="phone" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <MKInput variant="standard" type="email" label="Email" name="email" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    type="text"
                    label="Woonadres"
                    name="renters_address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Uw bericht"
                    name="message"
                    multiline
                    fullWidth
                    rows={6}
                  />
                </Grid>
                <Grid item xs={12} mt={6}>
                  <MKTypography item variant="p">
                    Verhuren
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    mt={10}
                    defaultValue="Reden verhuur..."
                    options={[
                      "Extra woning",
                      "Verhuizen",
                      "Belegging",
                      "Tijdelijk verhuren",
                      "Anders",
                    ]}
                    renderInput={(params) => (
                      <MKInput {...params} variant="standard" name="reason_renting" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    type="text"
                    label="Te verhuren adres"
                    name="renting_address"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} alignItems="center" ml={-1}>
                  <Switch checked={checked} onChange={handleChecked} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    ml={-1}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                    onClick={handleChecked}
                  >
                    &nbsp;&nbsp;Ik ga akkoord met de&nbsp;
                  </MKTypography>
                  <MKTypography
                    component="a"
                    href="/terms-and-conditions"
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    Algemene voorwaarden
                  </MKTypography>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={3} alignItems="center">
                <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
              </Grid>
              <Grid container item justifyContent="center" xs={12} mb={2} mt={3}>
                <MKButton
                  type="submit"
                  variant="gradient"
                  color="dark"
                  fullWidth
                  disabled={!checked}
                >
                  Send Message
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactFormRenters;
