/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";

// Imags
import bgImage from "assets/images/vastgoedverhuurmakelaardij/verhuur-en-beheer-tarieven-2.jpg";

function Pricing() {
  // const [activeTab, setActiveTab] = useState(0);
  // const [tabType, setTabType] = useState("monthly");
  //
  // const handleTabType = ({ currentTarget }, newValue) => {
  //   setActiveTab(newValue);
  //   setTabType(currentTarget.id);
  // };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 7 }}>
      <MKBox
        borderRadius="xl"
        shadow="lg"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <Container sx={{ pb: { xs: 12, lg: 22 }, pt: 12 }}>
          <Grid
            container
            item
            flexDirection="column"
            alignItems="center"
            xs={12}
            md={8}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography variant="h3" color="white" mb={4}>
              Bekijk onze scherpe tarieven
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <MKBox mt={-16}>
        <Container>
          <MKBox position="relative" zIndex={10} px={{ xs: 1, sm: 0 }}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={6}>
                <DefaultPricingCard
                  badge={{ color: "#ffce4d", label: "verhuur" }}
                  price={{ value: "Één maand huur inclusief BTW" }}
                  specifications={[
                    { label: "Vrijblijvend kennismakingsgesprek", includes: true },
                    { label: "Gratis waardebepaling", includes: true },
                    { label: "Professionele publicatie (incl. foto’s)", includes: true },
                    {
                      label: "Het uitvoeren van bezichtigingen en onderhandelingen",
                      includes: true,
                    },
                    { label: "Grondig screenen van de kandidaat-huurder(s)", includes: true },
                    { label: "Verzorgen van administratieve documenten", includes: true },
                    { label: "Verzorgen en begeleiden van opleveringen", includes: true },
                    { label: "Risico’s in kaart brengen", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "/contact-verhuren",
                    color: "dark",
                    label: "Start met verhuren",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <DefaultPricingCard
                  color="dark"
                  badge={{ color: "info", label: "Beheer" }}
                  price={{ value: "5% - 7%  van de maandhuur inclusief BTW", color: "light" }}
                  specifications={[
                    { label: "Innen en controleren van de huurpenningen", includes: true },
                    { label: "Opstellen van de maandelijkse huurafrekening", includes: true },
                    { label: "Het eerste aanspreekpunt voor de huurder(s)", includes: true },
                    { label: "Periodieke controles in de huurwoning(en)", includes: true },
                    { label: "Advies geven op het gebied van onderhoud", includes: true },
                    { label: "Zorg dragen voor goede dossiervorming", includes: true },
                    { label: "Het coördineren van verhuurmutaties", includes: true },
                    { label: "Opstellen van afrekeningen", includes: true },
                  ]}
                  action={{
                    type: "internal",
                    route: "/contact-verhuren",
                    color: "info",
                    label: "Alles onder één dak",
                  }}
                />
              </Grid>
            </Grid>
          </MKBox>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Pricing;
