/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Rental page sections
import Places from "pages/Offers/sections/Places";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import Socials from "../../examples/Socials";
import SearchBar from "../../examples/SearchBar";
import ContactForm from "../../examples/ContactForms/ContactForm";
import GoogleMapsWrapper from "../../examples/GoogleMaps";
import { useSelector } from "react-redux";

function Offers() {
  const woningen = useSelector((state) => state.woningen);
  const locaties = useSelector((state) => state.locaties);
  const markers =
    locaties &&
    locaties.locaties.map((l) => {
      if (!l.lat || !l.long || !l.image || !l.straat || !l.postcode || !l.plaats || !l.id) {
        return null;
      }
      const filteredWoning = woningen.woningen.filter((w) => w.id === l.id)[0];
      let huurprijs = 0;
      if (filteredWoning && filteredWoning.financieel && filteredWoning.financieel.overdracht) {
        huurprijs = `&euro; ${filteredWoning.financieel.overdracht.huurprijs},00`;
      }
      return {
        lat: parseFloat(l.lat),
        lng: parseFloat(l.long),
        text: ` <img src="${l.image}" alt="${l.straat}"> 
            <div>
              <p style="font-weight: bold; font-size: 16px">${l.straat}</p>
              ${l.postcode} ${l.plaats}<br />
              Huurprijs: ${huurprijs}<br />
              <a href="/woning/details/${l.id}" target="_blank" style="color: blue;">Bekijk</a>
            </div>`,
        className: "google-maps-popup-container",
      };
    });
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <GoogleMapsWrapper markers={markers} height={500} zoom={12} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -2,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <SearchBar />
        <Places />
        <ContactForm />
        <Socials />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Offers;
