import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import AboutUsOption from "./sections/AboutUsOption";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import routes from "../../routes";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";

// TODO
import bgImage from "../../assets/images/vastgoedverhuurmakelaardij/HOMEPAGE.webp";
import { List, ListItem } from "@mui/material";

function Vacancies() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <MKBox minHeight="15vh"></MKBox>
      <Card
        sx={{
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
          <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Vacatures
          </MKTypography>
          <MKBox mb={8}>
            <MKTypography variant="body2" align="center" color="text">
              Solliciteer en kom ons team versterken!
            </MKTypography>
          </MKBox>
          <Container>
            <Grid container>
              <Grid item xs={12} lg={5}>
                <MKTypography variant="h3" my={1}>
                  Functie Verhuurmakelaar
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Wij zijn op zoek naar een gemotiveerde collega die ons kantoor wil komen
                  versterken. In de functie verhuurmakelaar werk jij voor de verhuurder om het beste
                  resultaat te behalen. Vind je het leuk om met mensen te werken en ben je
                  resultaatgericht? Heb je een passie voor de woningmarkt en krijg je energie van
                  het binnenhalen en verhuren van nieuw aanbod? Dan sluit deze functie helemaal aan
                  op jou!
                </MKTypography>
              </Grid>
              <Grid item xs={12} lg={6} sx={{ ml: { lg: "auto" } }}>
                <Stack>
                  <MKTypography variant="h4" my={2}>
                    Functie vereisten
                  </MKTypography>
                  <List component="h5" sx={{ fontWeight: "normal" }}>
                    <ListItem>- Representatief</ListItem>
                    <ListItem>- Neemt initiatief</ListItem>
                    <ListItem>- Secuur werken</ListItem>
                    <ListItem>- Relevante werkervaring</ListItem>
                    <ListItem>- Beschikbaar 32-40 uur per week</ListItem>
                    <ListItem>- Communicatief vaardig in zowel Nederlands als Engels</ListItem>
                    <ListItem>- In bezit van een rijbewijs B</ListItem>
                    <ListItem>- Je beschikt over MBO-HBO werk- en denkniveau</ListItem>
                    <ListItem>- Relevante afgeronde studie in het vastgoed is een pré</ListItem>
                  </List>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={5}>
                <MKTypography variant="h4" mt={4} mb={1}>
                  Functieomschrijving
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={1}>
                  Als verhuurmakelaar ben je zeer zelfstandig en verantwoordelijk voor het
                  huurwoningaanbod in onze regio. Je werkt in ons kantoor gevestigd in het centrum
                  van Amstelveen en uiteraard op locatie in de te verhuren woningen. Taken die jij
                  uitvoert doe je geheel zelfstandig, hieronder wordt verstaan het aanbieden van
                  woningen, het uitvoeren van bezichtigingen, het opstellen van de benodigde
                  documenten en het verzorgen van mutaties in de woningportefeuille.
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Je mag van ons verwachten: <br />
                  - Een prettige werkomgeving <br />
                  - Ruimte om jezelf te ontwikkelen <br />
                  - Gevarieerde werkzaamheden <br />
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} lg={6} textAlign="center" sx={{ mx: "auto" }}>
                <MKTypography variant="h4" mt={4} mb={1}>
                  Ben jij wie wij zoeken?
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={1}>
                  Neem dan contact op met ons kantoor doormiddel van het sturen van een e-mail naar:
                  <a href="mailto:info@vastgoedverhuurmakelaardij.nl">
                    info@vastgoedverhuurmakelaardij.nl
                  </a>
                  . Wellicht ben jij straks onze nieuwe collega!
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Vacancies;
