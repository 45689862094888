/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import Features from "./sections/Features";
import routes from "routes";
import footerRoutes from "footer.routes";
import CallToAction from "../../examples/CallToAction";
import Socials from "../../examples/Socials";
import NewOffersBanner from "../../examples/NewOffersBanner";
import SearchBar from "../../examples/SearchBar";
import "./styles.css";
import Box from "@mui/material/Box";
import MKButton from "../../components/MKButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FiveSteps from "./sections/FiveSteps";
import Testimonials from "../../examples/Testimonials";
import bgImage from "assets/images/vastgoedverhuurmakelaardij/verhuur-beheer-cover-foto.webp";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="60vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Box maxWidth={800} mx="auto">
            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
              <MKTypography
                alignItems="center"
                variant="h1"
                color="white"
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("lg")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Vastgoed Verhuurmakelaardij{" "}
              </MKTypography>
            </Grid>
            {/* <Grid container item xs={12} lg={12} justifyContent="center" mx="auto"> */}
            {/*   <MKTypography variant="body1" color="white" textAlign="center" px={6}> */}
            {/*     - Gewoon goed - */}
            {/*   </MKTypography> */}
            {/* </Grid> */}
          </Box>
          <Grid container item xs={12} lg={12} justifyContent="center" mx="auto" mt={4}>
            <MKButton variant="gradient" color="info" size="large" href="/contact-verhuren">
              Neem contact op
              <ArrowForwardIcon />
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <SearchBar />
        <Features />
        <NewOffersBanner />
        <FiveSteps />
        <Testimonials />
        <CallToAction />
        <Socials />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
