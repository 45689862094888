import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText } from "@mui/material";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <Container>
      <Grid container justifyContent="center" mb={12}>
        <Grid item xs={12} md={8} my={6}>
          <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Veel gestelde vragen verhuurders
          </MKTypography>
          <MKBox mb={2}>
            <MKTypography variant="body2" align="center" color="text">
              Bij Vastgoed Verhuurmakelaardij snappen wij het nut van een goede FAQ. Staat jouw
              vraag er niet tussen, bel dan gerust met ons kantoor of stuur ons een e-mail!
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title="Hoe verhuur ik mijn woning?"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            Om te beginnen is het een goede eerste stap om contact met Vastgoed Verhuurmakelaardij
            op te nemen. Als u uw woning wilt verhuren is de volgende stap een vrijblijvende
            afspraak in te plannen om de mogelijkheden te bespreken en kan de verhuurmakelaar een
            waardebepaling afgeven. Na het ondertekenen van de “bemiddelingsopdracht tot
            dienstverlening” gaat Vastgoed Verhuurmakelaardij aan de slag met uw woning. Wij maken
            professionele foto’s van de woning en adverteren deze bij een groot publiek waaronder
            Funda en Pararius.
            <br />
            <br />
            Wij plannen de bezichtigingen in voor uw woning en laten de kandidaten de woning zien.
            Na een concreet voorstel van een kandidaat-huurder screenen wij de financiële situatie
            van deze kandidaat-huurder. Indien deze akkoord is dan presenteren wij u het
            huurvoorstel. Gaat u akkoord met het huurvoorstel, dan gaan wij over tot het opstellen
            van de benodigde documentatie, waaronder de huurovereenkomst.
            <br />
            <br />
            Zodra de huurder en u de huurovereenkomst hebben ondertekend innen wij de eerste
            betaling, inclusief de borgsom, van de huurder. Wij verrekenen onze
            bemiddelingsvergoeding met deze betaling en storten het restant zo spoedig mogelijk op
            uw rekeningnummer.
            <br />
            <br />
            Bij de sleuteloverdracht stellen wij een inspectierapport op. In dit rapport wordt de
            onderhoudsstaat van de verhuurde woning opgenomen. Dit inspectierapport bestaat uit een
            geschreven en visueel gedeelte. Wij vinden het belangrijk dit zorgvuldig uit te voeren
            zodat er achteraf geen misverstanden ontstaan.
            <br />
            <br />
            Het moment dat de huurder de woning gaat verlaten stellen wij nogmaals eenzelfde
            inspectierapport op en begeleiden wij u, als verhuurder, met de verrekening van de
            borgsom.
          </FaqCollapse>
          <FaqCollapse
            title="Welke huurovereenkomsten zijn er?"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            In Nederland kennen wij twee bekende huurcontracten. Vastgoed Verhuurmakelaardij werkt
            met de ROZ modellen in zowel het Nederlands als het Engels.
            <br />
            <br />
            Het Model A contract is een contract voor onbepaalde tijd met een afgesproken minimum
            duur. U kunt deze huurovereenkomst tussentijds als verhuurder niet opzeggen. De huurder
            mag deze huurovereenkomst opzeggen met één kalendermaand opzegtermijn, maar pas na de
            afgesproken minimum periode. Doorgaans is deze minimum duur 12 maanden.
            <br />
            <br />
            Het Model B contract staat bekend als een tijdelijk contract. Dit contract heeft een
            duidelijk afgesproken einddatum en kan maximaal twee jaar duren. U kunt deze
            huurovereenkomst als verhuurder tussentijds niet opzeggen. Pas drie maanden tot één
            maand voor het verstrijken van de huurovereenkomst kunt u als verhuurder de kennisgeving
            versturen naar de huurder, zodat het contract op de afgesproken datum eindigt. De
            huurder heeft het recht om vanaf de start van dit contract de overeenkomst met één
            kalendermaand opzegtermijn op te zeggen.
          </FaqCollapse>
          <FaqCollapse
            title="Welke risico’s zijn er?"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            Bij het verhuren van uw woning zijn er altijd risico’s, zoals bijvoorbeeld het
            debiteurenrisico en het risico op leegstand. U bent eindverantwoordelijke als het gaat
            om wat er in de woning afspeelt. Het is daarom van belang dat u goed wordt geadviseerd
            en met de juiste verhuurmakelaardij in zee gaat. Vastgoed Verhuurmakelaardij assisteert
            u voor, tijdens en na het gehele verhuurtraject.
            <br />
            <br />
            De verhuurmakelaar screened de kandidaat-huurder vooraf op de financiële situatie, om zo
            de risico’s zo goed mogelijk te beheersen. Daarnaast kunnen wij tijdens het
            verhuurtraject periodieke controles uitvoeren als wij optreden als beheerder van de
            woning. Voor meer informatie hierover verwijzen wij u graag naar onze{" "}
            <Link to={"/woning-verhuren"}>Verhuur</Link> pagina.
          </FaqCollapse>
          <FaqCollapse
            title="Aan wie mag ik mijn woning verhuren?"
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            U mag uw woning aan iedereen verhuren. Wij hanteren een minimum inkomenseis van drie
            maanden huur als bruto inkomen van de huurder(s). Zo verzekeren wij dat de huurder
            financieel sterk genoeg is om de huur te kunnen dragen, zelfs bij onverwachte uitgaven.
            <br />
            <br />
            Onze expertise ligt bij het verhuren aan expats en wij hebben goede banden met de
            bedrijven waar zij werken. U kunt hierbij denken aan TCS, Canon, verscheidene
            internationale banken en andere grote multinationals.
            <br />
            <br />
            Daarnaast kunt u uw woning ook verhuren aan woningdelers. Het maximum aantal huurders
            voor het verhuren aan woningdelers ligt op twee personen. Als u aan meer dan twee
            personen uw woning wilt verhuren heeft u een vergunning nodig van de gemeente.
          </FaqCollapse>
          <FaqCollapse
            title="Wat zijn de fiscale gevolgen?"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            Als verhuurder loopt u het risico dat uw verhuurde woning belast wordt in box 1, deze
            belasting box kent in tegenstelling tot box 3 een hoog belastingtarief. Het risico dat
            uw woning in box 1 valt kunt u verminderen het vastgoedbeheer van uw woning uit te
            besteden aan Bregman Vastgoedbeheer. Box 3 kent een aanmerkelijk lager belastingtarief.
            In box 3 worden de huurinkomsten niet belast.
            <br />
            <br />
            Over de waarde van de woning wordt echter wel belasting geheven. Als u een lening heeft
            afgesloten op de te verhuren woning geeft u deze ook in box 3 aan. Het is wel mogelijk
            om de hypotheekschuld in mindering te brengen, waardoor het belastbaar vermogen in box 3
            lager uitvalt.
            <br />
            <br />
            Het is daarom in uw voordeel om het vastgoedbeheer van uw woning uit te besteden aan
            Bregman Vastgoedbeheer. Voor meer informatie over het uitbesteden van het vastgoedbeheer
            verwijzen wij u graag door naar onze <Link to={"/woning-verhuren"}>Verhuur</Link>{" "}
            pagina.
            <br />
            <br />
            Wij adviseren u altijd om met uw belastingadviseur in gesprek te gaan om uw
            mogelijkheden te bespreken.
          </FaqCollapse>
          <FaqCollapse
            title="Ben ik verzekerd als ik mijn huis verhuur?"
            open={collapse === 6}
            onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
          >
            Het is belangrijk om vooraf een opstalverzekering, een glasverzekering en een
            aansprakelijkheidsverzekering af te sluiten. Indien de woning gemeubileerd wordt
            verhuurd adviseren wij ook een inboedelverzekering af te sluiten. Wij adviseren u om met
            uw verzekeraar na te gaan wat er mogelijk is. Bij sommige woningen is er een collectieve
            VvE verzekering aanwezig, dit kunt u nagaan bij uw VvE.
          </FaqCollapse>
          <FaqCollapse
            title="Gemeentelijke belastingen en heffingen"
            open={collapse === 7}
            onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
          >
            Wanneer u als verhuurder uw woning gaat verhuren komt hier uiteraard gemeentelijke
            belastingen en heffingen bij kijken. Als eigenaar bent u verplicht het eigenaarsdeel te
            betalen van deze belastingen en heffingen te betalen, zoals bijvoorbeeld de
            onroerendzaakbelasting (OZB) en de rioolheffing. Deze kosten mogen niet doorberekend
            worden aan de huurder. De huurder draagt zorg voor de gebruikerslasten waaronder vallen:
            <List>
              <ListItem>- Afvalstoffenheffingen</ListItem>
              <ListItem>- Waterschapsbelasting Ingezetenen en de Zuiveringsheffing</ListItem>
              <ListItem>- Huurdersdeel rioolheffing</ListItem>
            </List>
          </FaqCollapse>
          <FaqCollapse
            title="Waartoe ben ik als verhuurder verplicht bij het verhuren van een huurwoning?"
            open={collapse === 8}
            onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
          >
            Als verhuurder bent u wettelijk verplicht de woning aan de huurder ter beschikking te
            stellen en goed te onderhouden. Daarnaast dient u de huurder van een rustig woongenot te
            verzekeren.
            <br />
            <br />
            Om een woning te verhuren bent u verplicht eigenaar te zijn van deze woning. Wij
            adviseren u contact op te nemen met uw hypotheekverstrekker voordat u de woning te huur
            aanbiedt. Niet alle hypotheekverstrekkers gaan hier zondermeer mee akkoord en zullen
            toestemming moeten verstrekken. Daarnaast zal u in uw koopakte moeten nagaan of er
            verhuurbeperkingen in staan. U bent als verhuurder tevens verplicht om de huurder een
            definitief energielabel te overhandigen.
          </FaqCollapse>
          <FaqCollapse
            title="Wat zijn de voordelen van het uitbesteden van het vastgoedbeheer?"
            open={collapse === 9}
            onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
          >
            Bregman Vastgoedbeheer kan voor u het volledige vastgoedbeheer van uw woning verzorgen.
            Wij dragen niet alleen zorg voor het financieel en technisch beheer, maar voeren
            daarnaast ook periodieke controles uit. Hierdoor kunt u met een gerust hart het beheer
            van uw woning uit handen geven.
            <br />
            <br />
            Wij hebben een duidelijk zicht op de onderhoudsstaat van uw woning door de periodieke
            controles die wij uitvoeren. De periodieke controles geven ook een goed inzicht in de
            naleving van de afgesproken regels door de huurder. Daarnaast kunnen wij preventief
            actie te ondernemen en mogelijke schades/situaties voor zijn. Wij staan voor zowel de
            huurder als de verhuurder klaar voor vragen of advies. Deze werkwijze kan als resultaat
            hebben dat een zittende huurder de woning langer wil huren.
            <br />
            <br />
            Met onze werkwijze leveren wij een positieve bijdrage aan uw vastgoedrendement.
            Daarnaast heeft het uitbesteden van het vastgoedbeheer van uw woning ook fiscale
            voordelen. Voor meer informatie over het uit besteden van het vastgoedbeheer verwijzen
            wij u graag naar onze <Link to={"/woning-verhuren"}>Verhuur</Link> pagina.
          </FaqCollapse>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Faq;
