/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
import { useEffect } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/company/about-us";
import ContactUs from "layouts/pages/support/contact-us";
import Privacy from "layouts/pages/support/privacy";
import Renters from "./pages/Renters";
import Offers from "./pages/Offers";
import Terms from "./pages/Support/Terms";
import RentalAndManagementPage from "layouts/pages/company/pricing";
import ContactUsRental from "./pages/Support/ContactUsRental";
import HomeDetails from "./pages/HomeDetails";
import Presentation from "layouts/pages/presentation";
import Vacancies from "./pages/Vacancies";
import RentalAndManagementFaq from "./pages/Company/RentalAndManagementFaq";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const Redirect = ({ to }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to);
  }, [navigate, to]);

  return null;
};
Redirect.propTypes = {
  to: PropTypes.string.isRequired,
};

const routes = [
  {
    name: "Home",
    // icon: <Icon>home</Icon>,
    route: "/",
    component: <Presentation />,
  },
  {
    name: "aanbod",
    // icon: <Icon>home</Icon>,
    route: "/aanbod",
    component: <Offers />,
  },
  {
    name: "Verhuur",
    // icon: <Icon>maps_home_work</Icon>,
    route: "/woning-verhuren",
    component: <RentalAndManagementPage />,
    collapse: [
      {
        name: "Verhuur",
        route: "/woning-verhuren",
        component: <RentalAndManagementPage />,
        hide: true,
      },
      {
        name: "FAQ verhuurders",
        route: "/veel-gestelde-vragen-verhuurders",
        component: <RentalAndManagementFaq />,
      },
    ],
  },
  {
    name: "Huurders",
    // icon: <Icon>cottage</Icon>,
    route: "/huurders",
    component: <Renters />,
  },
  {
    name: "Wie zijn wij?",
    // icon: <Icon>face</Icon>,
    route: "/over-ons",
    component: <AboutUs />,
    collapse: [
      {
        name: "Wie zijn wij?",
        route: "/over-ons",
        component: <AboutUs />,
        hide: true,
      },
      {
        name: "Vacatures",
        route: "/vacatures",
        component: <Vacancies />,
      },
    ],
  },
  // {
  //   name: "Wie zijn wij?",
  //   icon: <Icon>face</Icon>,
  //   collapse: [
  //     {
  //       name: "Over ons",
  //       route: "/about-us",
  //       component: <AboutUs />,
  //     },
  //     {
  //       name: "FAQ",
  //       route: "/veel-gestelde-vragen-verhuurders",
  //       component: <Faq />,
  //     },
  //   ],
  // },
  {
    name: "Contact",
    icon: <Icon>contacts</Icon>,
    route: "/contact",
    component: <ContactUs />,
  },
  {
    name: "Woning details",
    icon: "",
    route: "/woning/details/:id",
    component: <HomeDetails />,
    hide: true,
  },
  {
    name: "Privacyverklaring",
    route: "/privacy",
    component: <Privacy />,
    hide: true,
  },
  {
    name: "Algemene voorwaarden",
    route: "/terms-and-conditions",
    component: <Terms />,
    hide: true,
  },
  {
    name: "Contact verhuren",
    route: "/contact-verhuren",
    component: <ContactUsRental />,
    hide: true,
  },
  {
    name: "FAQ verhuurders",
    route: "/veel-gestelde-vragen-verhuurders",
    component: <RentalAndManagementFaq />,
    hide: true,
  },
  {
    name: "Wie zijn wij?",
    route: "/about-us",
    // eslint-disable-next-line react/display-name
    component: <Redirect to="/over-ons" />,
    hide: true,
  },
  {
    name: "Verhuur",
    route: "/verhuur-en-beheer",
    // eslint-disable-next-line react/display-name
    component: <Redirect to="/woning-verhuren" />,
    hide: true,
  },
];

export default routes;
