/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "dark",
        }}
        sticky
      />
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="dark"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    Privacyverklaring
                  </MKTypography>
                  <MKTypography variant="body2" color="white" opacity={0.8}>
                    Last modified: 3 Juni 2022
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Introductie
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Vastgoed Verhuurmakelaardij B.V., gevestigd aan Van Heuven Goedhartlaan 13D
                    1181LE te Amstelveen, is verantwoordelijk voor de verwerking van
                    persoonsgegevens zoals weergegeven in deze privacyverklaring.
                    <br />
                    <br />
                    Contactgegevens: <br />
                    https://vastgoedverhuurmakelaardij.nl <br />
                    Van Heuven Goedhartlaan 13D 1181LE te <br />
                    Amstelveen <br />
                    020-2212015 <br />
                    info@vastgoedverhuurmakelaardij.nl
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Persoonsgegevens die wij verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text">
                    Vastgoed Verhuurmakelaardij B.V. verwerkt uw persoonsgegevens doordat u gebruik
                    maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder
                    vindt u een overzicht van de persoonsgegevens die wij verwerken:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Voor- en achternaam
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Adresgegevens
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Telefoonnummer
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        E-mailadres
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Locatiegegevens
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Gegevens over uw activiteiten op onze website
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Gegevens over uw surfgedrag over verschillende websites heen (bijvoorbeeld
                        omdat dit bedrijf onderdeel is van een advertentienetwerk)
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Internetbrowser en apparaat type
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. verwerkt de volgende bijzondere en/of gevoelige
                    persoonsgegevens van u:
                    <br />
                    <br />
                    kredietwaardigheidscheck Bij Vastgoed Verhuurmakelaardij B.V. is het mogelijk om
                    achteraf te betalen voor de producten die u koopt. Om dit mogelijk te maken en u
                    en onszelf te beschermen tegen misbruik, laten we uw kredietwaardigheid toetsen.
                    Dit doen wij door de noodzakelijke persoonsgegevens (waaronder uw adresgegevens)
                    te verstrekken aan een kredietwaardigheidsbeoordelaar [naam & eventueel
                    adresgegevens beoordelaar], die deze gegevens alleen voor dit doel mag
                    gebruiken.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. verwerkt uw persoonsgegevens voor de volgende
                    doelen:
                  </MKTypography>
                  <MKBox component="ul" my={3} ml={6}>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Het afhandelen van uw betaling
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        Verzenden van onze nieuwsbrief en/of reclamefolder
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening
                        uit te kunnen voeren
                      </MKTypography>
                    </MKBox>
                    <MKBox component="li">
                      <MKTypography variant="body2" color="text" fontWeight="regular">
                        U te informeren over wijzigingen van onze diensten en producten
                      </MKTypography>
                    </MKBox>
                  </MKBox>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Geautomatiseerde besluitvorming
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. neemt #responsibility op basis van
                    geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen
                    kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    computerprogramma's of -systemen, zonder dat daar een mens (bijvoorbeeld een
                    medewerker van Vastgoed Verhuurmakelaardij B.V.) tussen zit. Vastgoed
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Verhuurmakelaardij B.V. gebruikt computerprogramma's of -systemen.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Hoe lang we persoonsgegevens bewaren
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. bewaart uw persoonsgegevens niet langer dan
                    strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden
                    verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende
                    (categorieën) van persoonsgegevens: 24 maanden.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Delen van persoonsgegevens met derden
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. verstrekt uitsluitend aan derden en alleen als
                    dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan
                    een wettelijke verplichting.
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Cookies, of vergelijkbare technieken, die wij gebruiken
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. gebruikt functionele, analytische en tracking
                    cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze
                    website wordt opgeslagen in de browser van uw computer, tablet of smartphone.
                    Vastgoed Verhuurmakelaardij B.V. gebruikt cookies met een puur technische
                    functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat
                    bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook
                    gebruikt om de website goed te laten werken en deze te kunnen optimaliseren.
                    Daarnaast plaatsen we cookies die uw surfgedrag bijhouden zodat we op maat
                    gemaakte content en advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze
                    website hebben wij u al geïnformeerd over deze cookies en toestemming gevraagd
                    voor het plaatsen ervan. U kunt zich afmelden voor cookies door uw
                    internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast
                    kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw
                    browser verwijderen. Zie voor een toelichting:
                    https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Gegevens inzien, aanpassen of verwijderen
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
                    verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de
                    gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw
                    persoonsgegevens door Vastgoed Verhuurmakelaardij B.V. en heeft u het recht op
                    gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen
                    om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of
                    een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot
                    inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of
                    verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw
                    persoonsgegevens sturen naar info@vastgoedverhuurmakelaardij.nl. Om er zeker van
                    te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van
                    uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw
                    pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het
                    paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
                    bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier
                    weken, op uw verzoek. Vastgoed Verhuurmakelaardij B.V. wil u er tevens op wijzen
                    dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale
                    toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:
                    https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                  </MKTypography>
                  <MKTypography variant="h5" mt={6} mb={3}>
                    Hoe wij persoonsgegevens beveiligen
                  </MKTypography>
                  <MKTypography variant="body2" color="text" fontWeight="regular">
                    Vastgoed Verhuurmakelaardij B.V. neemt de bescherming van uw gegevens serieus en
                    neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste
                    openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft
                    dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik,
                    neem dan contact op met onze klantenservice of via
                    info@vastgoedverhuurmakelaardij.nl
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Privacy;
