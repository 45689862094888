import PropTypes from "prop-types";
import SimpleBookingCard from "../Cards/BookingCards/SimpleBookingCard";
import MKBox from "../../components/MKBox";
import { getBadge } from "../Helpers";

const getPrice = (price) => {
  if (!price) return null;
  return "euro; " + price.toString();
};

const getRoomText = (roomCountStr) => {
  if (!roomCountStr) return null;
  if ("1" === roomCountStr) return "1 Kamer";
  return roomCountStr + " Kamers";
};

const getSurfaceSize = (size) => {
  if (!size) return null;
  return size.toString() + "m2";
};

const getImage = (images) => {
  if (!images.length) return ""; // TODO: Set empty default
  let arrayForSort = [...images];
  arrayForSort.sort((a, b) => a.volgnummer - b.volgnummer);
  return arrayForSort[0].link + "&resize=4";
};

function WoningOffer({ content }) {
  const { id, adres, algemeen, media, financieel, marketing } = content;
  const actionProps = {
    type: "internal",
    route: "/woning/details/" + id,
    color: "info",
    label: "Bekijk >",
  };

  return (
    <MKBox mt={3}>
      <SimpleBookingCard
        image={getImage(media)}
        title={adres.straat}
        description={adres.plaats.charAt(0).toUpperCase() + adres.plaats.toLowerCase().slice(1)}
        categories={[
          getPrice(financieel.overdracht.huurprijs),
          getRoomText(algemeen.aantalKamers),
          getSurfaceSize(algemeen.woonoppervlakte),
        ].filter((n) => n)}
        action={actionProps}
        badge={getBadge(financieel.overdracht.status, marketing.publicatiedatum)}
      />
    </MKBox>
  );
}

WoningOffer.propTypes = {
  content: PropTypes.object.isRequired,
};

export default WoningOffer;
