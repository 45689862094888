import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SimpleInfoCard from "../../../../examples/Cards/InfoCards/SimpleInfoCard";

function Features() {
  return (
    <Container>
      <Grid container spacing={3} mt={10}>
        <Grid item xs={12} md={6} lg={3}>
          <SimpleInfoCard
            color="primary"
            icon="accessibility_icon"
            title="Transparant"
            description=""
            direction="center"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SimpleInfoCard
            color="info"
            icon="health_and_safety_icon"
            title="Betrouwbaar"
            description=""
            direction="center"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SimpleInfoCard
            color="warning"
            icon="update"
            title="No-Cure, No-Pay"
            description=""
            direction="center"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SimpleInfoCard
            color="error"
            icon="maps_home_work_icon"
            title="Marktkennis"
            description=""
            direction="center"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Features;
