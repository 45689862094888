import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import routes from "../../routes";
import MKBox from "../../components/MKBox";
import bgImage from "../../assets/images/vastgoedverhuurmakelaardij/regus-kantoor-foto.webp";
import Card from "@mui/material/Card";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";
import MKTypography from "../../components/MKTypography";
import MKButton from "../../components/MKButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "examples/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import MKBadge from "../../components/MKBadge";
import "./styles.css";
import { formatText } from "../../examples/Helpers";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const getStatusBadge = (status) => {
  if (!status) return "";
  return (
    <MKBadge
      badgeContent={formatText(status)}
      container
      color={status === "BESCHIKBAAR" ? "success" : "warning"}
      className="home-details-label"
      size="lg"
    />
  );
};

const tableValueFormat = (text) => {
  if (!text) return "-";
  if (typeof text !== "string") return text;
  text = text.replaceAll("_", " ");
  text = text.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

function HomeDoenstExist() {
  // TODO
  return <p>404</p>;
}

function HomeDetails() {
  let { id } = useParams();
  const woningen = useSelector((state) => state.woningen);

  if (woningen.status === "loading") return <p>Loading</p>;

  const woningIndex = woningen.woningen.findIndex((e) => e.id === parseInt(id));
  const woning = woningen.woningen[woningIndex];

  if (!woning) return <HomeDoenstExist />;

  const algemeenRows = [
    // { name: "Type woning", value: woning.algemeen.woonhuistype || "" },
    { name: "Type woning", value: woning.object.type.objecttype || "" },
    {
      name: "Woonhuissoort",
      value: woning.algemeen.woonhuissoort || woning.algemeen.appartementsoort || "",
    },
    { name: "Woonoppervlakte", value: woning.algemeen.woonoppervlakte + " m2" || "" },
    { name: "Aantal kamers", value: woning.algemeen.aantalKamers || "" },
    { name: "Bouwvorm", value: woning.algemeen.bouwvorm || "" },
    { name: "Bouwjaar", value: woning.algemeen.bouwjaar || "" },
    { name: "Onderhoud binnen", value: woning.algemeen.onderhoudswaarderingBinnen || "" },
    { name: "Onderhoud buiten", value: woning.algemeen.onderhoudswaarderingBuiten || "" },
    { name: "Parkeercapaciteit", value: woning.detail.buitenruimte.parkeercapaciteit || "" },
    { name: "Aantal bergingen", value: woning.detail.buitenruimte.aantalSchurenBergingen || "" },
  ];

  const energyRows = [
    { name: "Energie index", value: woning.algemeen.energieindex || "" },
    { name: "Energie klasse", value: woning.algemeen.energieklasse || "" },
    { name: "EPC", value: woning.algemeen.epc || "" },
    { name: "CV ketel bouwjaar", value: woning.algemeen.cvKetelBouwjaar || "" },
    { name: "CV ketel brandstof", value: woning.algemeen.cvKetelBrandstof || "" },
  ];

  const images = woning.media.filter((img) => img.vrijgave);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="30vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={{ xs: 6, sm: 12 }}>
          <Container>
            <Grid container item xs={12} justifyContent="center" mx="auto">
              <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
                <Card
                  sx={{
                    py: 3,
                    px: 8,
                  }}
                >
                  {woning.financieel.overdracht.status &&
                    getStatusBadge(woning.financieel.overdracht.status)}

                  <MKTypography variant="h3">{woning.adres.straat}</MKTypography>
                  <MKTypography variant="p">{woning.adres.plaats}</MKTypography>
                  {woning.financieel.overdracht.huurprijs && (
                    <MKTypography variant="p" mb={2}>
                      &euro;{woning.financieel.overdracht.huurprijs}{" "}
                      {formatText(woning.financieel.overdracht.huurconditie).toLowerCase()}
                    </MKTypography>
                  )}
                  <MKButton color="primary" href="tel:020-2212015">
                    020-2212015
                  </MKButton>
                </Card>
              </MKBox>
              <Grid container justifyContent="center" py={6} spacing={4}>
                <Grid item xs={12} md={10}>
                  <MKBox mb={5}>
                    <Breadcrumbs
                      routes={[
                        { label: "Home", route: "/" },
                        { label: "Aanbod", route: "/aanbod" },
                        { label: woning.adres.straat, route: window.location.pathname },
                      ]}
                    />
                  </MKBox>
                </Grid>

                {images && images.length && (
                  <Grid item xs={12} md={10}>
                    <Box mb={4}>
                      <MKTypography variant="h4" mb={2}>
                        Afbeeldingen
                      </MKTypography>
                      <Carousel
                        autoPlay={true}
                        indicators={true}
                        swipe={true}
                        cycleNavigation={true}
                        navButtonsAlwaysVisible={true}
                        sx={{ minHeight: 600 }}
                      >
                        {images
                          .sort((a, b) => a.volgnummer - b.volgnummer)
                          .map((item, i) => (
                            <img
                              src={item.link + "&resize=4"}
                              alt={item.title}
                              key={i}
                              width="100%"
                            />
                          ))}
                      </Carousel>
                    </Box>
                  </Grid>
                )}

                {woning.teksten.aanbiedingstekst && (
                  <Grid item xs={12} md={10}>
                    <Box mb={2} mt={4}>
                      <MKTypography variant="h4" mb={2}>
                        Omschrijving
                      </MKTypography>
                      <MKTypography variant="p" sx={{ whiteSpace: "pre-line" }}>
                        {woning.teksten.aanbiedingstekst}
                      </MKTypography>
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12} md={10} lg={5}>
                  <MKTypography variant="h4" mb={2} mt={4}>
                    Algemeen
                  </MKTypography>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableBody>
                        {algemeenRows.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {tableValueFormat(row.value)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} md={10} lg={5}>
                  <MKTypography variant="h4" mb={2} mt={4}>
                    Energie
                  </MKTypography>
                  <TableContainer>
                    <Table aria-label="customized table">
                      <TableBody>
                        {energyRows.map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {tableValueFormat(row.value)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <MKBox textAlign="center" mt={10}>
            {/* <Link to="/aanbod"> */}
            <MKButton color="secondary" size="large" variant="outlined">
              <MKTypography component="a" href="/aanbod" variant="button">
                Terug naar alle projecten
              </MKTypography>
            </MKButton>
            {/* </Link> */}
          </MKBox>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default HomeDetails;
