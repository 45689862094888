/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Pricing page sections
import PricingSection from "pages/Company/RentalAndManagement/sections/Pricing";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";
import Card from "@mui/material/Card";
import Socials from "../../../examples/Socials";
import NewOffersBanner from "../../../examples/NewOffersBanner";

import bgImage from "assets/images/vastgoedverhuurmakelaardij/homepage-cover.webp";
import MKButton from "../../../components/MKButton";
import Testimonials from "../../../examples/Testimonials";
import Faq from "../RentalAndManagementFaq/Faq";
import Box from "@mui/material/Box";

function RentalAndManagement() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "call",
          route: "",
          label: "020-2212015",
          color: "info",
        }}
        sticky
      />
      {/* <MKBox minHeight="15vh"></MKBox> */}

      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Box maxWidth={900} mx="auto">
            <Grid container item xs={12} lg={12} justifyContent="center" mx="auto">
              <MKTypography
                alignItems="center"
                variant="h1"
                color="white"
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("lg")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Start vandaag en verhoog ook uw rendement{" "}
              </MKTypography>
            </Grid>
          </Box>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          container
          item
          xs={12}
          lg={10}
          pt={4}
          pb={2}
          justifyContent="center"
          mx="auto"
          textAlign="center"
        >
          <MKTypography variant="h2" mb={1}>
            Werkwijze
          </MKTypography>
          <MKTypography variant="body2" color="text">
            De werkwijze die wij hanteren is gebaseerd op transparantie en het No-Cure,
            No-Pay-principe, wat betekent dat u pas betaalt als wij een geschikte huurder hebben
            gevonden. Dankzij onze samenwerking met Funda en Pararius kunnen wij uw woning snel en
            aan een breed publiek presenteren. Wij ontvangen aanmeldingen via zowel onze platformen
            als ons uitgebreide netwerk.. Bij Vastgoed Verhuurmakelaardij kunt u rekenen op
            deskundig advies, persoonlijk contact en uitstekende begeleiding gedurende het hele
            verhuurproces.
          </MKTypography>
          <MKTypography variant="body2" color="text" pt={2}>
            Onze toewijding gaat verder dan zakelijke transacties. Wij streven naar het opbouwen van
            sterke en betrouwbare relaties met zowel onze klanten als huurders. Als makelaarskantoor
            voelen wij de verantwoordelijkheid om uitstekende service te bieden, en dit vormt de
            kern van onze missie.
          </MKTypography>
          <MKButton
            variant="gradient"
            color="info"
            size="medium"
            href="/over-ons"
            sx={{ marginTop: "20px" }}
          >
            Wie zijn wij?
          </MKButton>
        </Grid>
        <MKBox p={3}>
          <PricingSection />
          <Faq />
        </MKBox>
        <Testimonials />
        <NewOffersBanner />
        <Socials />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default RentalAndManagement;
