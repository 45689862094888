// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";
import MKButton from "../../../components/MKButton";

function FeaturesThree() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container item xs={12} lg={10} justifyContent="center" mx="auto" textAlign="center">
          <MKTypography variant="h2" mb={1}>
            Waarom kiezen voor Vastgoed Verhuurmakelaardij
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Bij Vastgoed verhuurmakelaardij begrijpen wij de uitdagingen waarmee u wordt
            geconfronteerd. Wij zijn gespecialiseerd in het bieden van op maat gemaakte verhuur- en
            beheeroplossingen om uw belangen te behartigen. Onze toegewijde en gepassioneerde aanpak
            is erop gericht om uw risico’s te minimaliseren en uw rendement te maximaliseren! De
            vastgoedmarkt is voortdurend in beweging. Ontwikkelingen zoals de (toekomstige) status
            van uw huurwoning en de impact van nieuwe BOX-3 wetgeving volgen elkaar razendsnel op.
            Het is tijd om een strategische partner aan uw zijde te hebben om uw investeringen te
            beschermen en uw rendement te optimaliseren.
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={10}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pt={2}
          pb={5}
        >
          <MKButton variant="contained" color="info" key="/over-ons" href="/over-ons">
            Wie zijn wij?
          </MKButton>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="primary"
              icon="accessibility_icon"
              title="Transparant"
              description=""
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="info"
              icon="health_and_safety_icon"
              title="Betrouwbaar"
              description=""
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="warning"
              icon="update"
              title="No-Cure, No-Pay"
              description=""
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SimpleInfoCard
              color="error"
              icon="maps_home_work_icon"
              title="Marktkennis"
              description=""
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesThree;
