import { configureStore } from "@reduxjs/toolkit";
import woningenReducer from "./slices/woningenSlice";
import locatiesReducer from "./slices/locatiesSlice";

export default configureStore({
  reducer: {
    woningen: woningenReducer,
    locaties: locatiesReducer,
  },
  devTools: true,
});
