import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelpCenter page components
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <Container>
      <Grid container justifyContent="center" mb={12}>
        <Grid item xs={12} md={6} my={6}>
          <MKTypography variant="h2" align="center" fontWeight="bold" gutterBottom>
            Veel gestelde vragen
          </MKTypography>
          <MKBox mb={2}>
            <MKTypography variant="body2" align="center" color="text">
              Bij Vastgoed Verhuurmakelaardij snappen wij het nut van een goede FAQ. Staat jouw
              vraag er niet tussen, bel dan gerust met ons kantoor of stuur ons een e-mail!
            </MKTypography>
          </MKBox>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title="Hoe gaat het huurproces bij Vastgoed Verhuurmakelaardij?"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            Na het bezichtigen van de huurwoning doe je een schriftelijk voorstel aan de
            verhuurmakelaar. De verhuurmakelaar vraagt jou om een <b>paspoort</b>, een{" "}
            <b>arbeidsovereenkomst</b> of <b>werkgeversverklaring</b>, drie recente{" "}
            <b>loonstrookjes</b>, <b>bankafschriften</b> van ontvangen salarissen van de afgelopen
            drie maanden en <b>persoonlijke informatie</b> toe te sturen. Indien je een partner hebt
            geldt dit zowel voor jou als je partner. De verhuurmakelaar zal het voorstel verifiëren
            en stuurt jou een intentieverklaring toe. Zodra je deze intentieverklaring tekent kan de
            verhuurmakelaar jouw voorstel bespreken met de verhuurder van de woning. Als het
            voorstel geaccepteerd wordt stuurt de verhuurmakelaar een factuur, voor de eerste
            betaling, tezamen met de huurovereenkomst.
          </FaqCollapse>
          <FaqCollapse
            title="Wat kan ik verwachten zodra ik de huurovereenkomst opzeg?"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            Een opzegging doe je schriftelijk per brief of mail en richt je aan de verhuurder en/of
            beheerder. De verhuurmakelaar maakt twee afspraken met jou. Één afspraak voor de
            voorinspectie en de andere afspraak voor de daadwerkelijke oplevering.{" "}
            <b>
              Let op: je dient de woning hetzelfde op te leveren aan de verhuurder zoals jij de
              woning van de verhuurder hebt mogen ontvangen.
            </b>{" "}
            Bij het niet correct opleveren van de woning wordt er gekeken naar een herstelplan. Dit
            kan betekenen dat jouw borg gebruikt wordt voor de herstelwerkzaamheden.
          </FaqCollapse>
          <FaqCollapse
            title="Hulp bij het aansluiten van de nutsvoorzieningen en/of internet en tv"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            Vastgoed verhuurmakelaardij biedt jou graag assistentie aan bij het aansluiten van de
            nutsvoorzieningen in de nieuwe huurwoning. Wij moeten toestemming vragen aan jou,
            voordat wij persoonsgegevens verstrekken aan onze samenwerkingspartner. Nadat er
            toestemming is gegeven neemt onze samenwerkingspartner contact met jou op.{" "}
            <b>Voordeel: hieraan zijn geen kosten verbonden!</b> Vastgoed Verhuurmakelaar verzorgt
            deze service geheel gratis aan haar huurders.
          </FaqCollapse>
          <FaqCollapse
            title="Wie is er verantwoordelijk voor het onderhoud in de woning?"
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            Tijdens de huurperiode ben jij als huurder verantwoordelijk voor het onderhoud binnen de
            woning en eventuele buitenruimte(s). Schade die voortvloeit uit nalatigheid in onderhoud
            of misbruik van bijvoorbeeld apparaten, zijn <u>altijd</u> voor jouw rekening. Als
            huurder heb je een <b>meldplicht</b> naar de beheerder en/of eigenaar. Om hoog oplopende
            kosten te voorkomen attenderen wij je erop deze schades direct te melden.
          </FaqCollapse>
          <FaqCollapse
            title="Mag een verhuurmakelaar kosten in rekening brengen bij mij?"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            Een verhuurmakelaar die in opdracht van de verhuurder werkt mag <u>nooit</u> kosten in
            rekening brengen bij jou als huurder. Hierbij kan je denken aan{" "}
            <b>bemiddelingsvergoeding</b>, <b>contractkosten</b> of <b>sleutelgeld</b>.
          </FaqCollapse>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Faq;
