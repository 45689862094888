/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MKButton from "../../components/MKButton";
import React, { Fragment } from "react";
import MKBadge from "../../components/MKBadge";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import { getBadge } from "../Helpers";

const getFullBadge = (status, datum) => {
  const badgeObj = getBadge(status, datum);
  if (!badgeObj) return null;
  return (
    <MKBadge
      badgeContent={badgeObj.text}
      container
      color={badgeObj.color}
      className="new-offers-badge"
    />
  );
};

const getImage = (images) => {
  if (!images.length) return "";
  let arrayForSort = [...images];
  arrayForSort.sort((a, b) => a.volgnummer - b.volgnummer);
  return arrayForSort[0].link + "&resize=4";
};

const getPrice = (price) => {
  if (!price) return "-";
  return "euro; " + price.toString();
};

const getRoomText = (roomCountStr) => {
  if (!roomCountStr) return "-";
  if ("1" === roomCountStr) return "1 Kamer";
  return roomCountStr + " Kamers";
};

const getSurfaceSize = (size) => {
  if (!size) return "-";
  return size.toString() + "m2";
};

function NewOffersBanner() {
  const woningen = useSelector((state) => state.woningen);

  const woningenCopy = [...woningen.woningen];
  const newOfferWoningen = woningenCopy
    .sort(
      (a, b) =>
        Number(new Date(b.marketing.publicatiedatum)) -
        Number(new Date(a.marketing.publicatiedatum))
    )
    .slice(0, 3)
    .map((woning, i) => {
      return (
        <Grid item xs={12} md={6} lg={4} sx={{ pt: 4, pb: 5 }} key={i}>
          <Box px={2}>
            <Link to={"/woning/details/" + woning.id}>
              <Box sx={{ boxShadow: 4, overflow: "hidden" }} display="flex">
                <img
                  src={getImage(woning.media)}
                  alt={woning.media.titel}
                  width="100%"
                  height="auto"
                  className="new-offers-image"
                />
              </Box>
              {woning.financieel.overdracht.status && woning.marketing.publicatiedatum
                ? getFullBadge(
                    woning.financieel.overdracht.status,
                    woning.marketing.publicatiedatum
                  )
                : ""}
              <MKTypography
                display="block"
                variant="button"
                color="text"
                fontWeight="regular"
                mt={1.5}
              >
                {[
                  getPrice(woning.financieel.overdracht.huurprijs),
                  getRoomText(woning.algemeen.aantalKamers),
                  getSurfaceSize(woning.algemeen.woonoppervlakte),
                ].map((category, i, row) => (
                  <Fragment key={category}>
                    {category.includes("euro;") ? <>&euro;</> : ""}
                    {category.replace("euro;", "")}{" "}
                    {i + 1 === row.length ? "" : <>&nbsp;&bull;&nbsp;</>}
                  </Fragment>
                ))}
              </MKTypography>
              <MKTypography variant="body1" mt={0.5}>
                {woning.adres.plaats}
              </MKTypography>
              <MKTypography variant="body2">{woning.adres.straat}</MKTypography>
            </Link>
          </Box>
        </Grid>
      );
    });

  return !woningen.woningen.length ? (
    <></>
  ) : (
    <MKBox position="relative" variant="gradient" bgColor="light" pt={5} pb={2} mx={-2}>
      <MKBox width="100%" opacity={0.1} />
      <Container>
        <MKBox textAlign="center">
          <MKTypography variant="h4">Nieuwe aanbiedingen</MKTypography>
        </MKBox>
        <Grid container justifyContent="center" sx={{ position: "relative", zIndex: 3 }}>
          {newOfferWoningen || (
            <Grid item md={12} display="flex" justifyContent="center" height={230} mt={10}>
              <CircularProgress color="primary" size="5rem" />
            </Grid>
          )}
        </Grid>
        <MKBox textAlign="center" mb={4}>
          <MKButton color="primary" size="large" variant="outlined" href="/aanbod">
            Bekijk het gehele aanbod
          </MKButton>
        </MKBox>
      </Container>
    </MKBox>
  );
}

export default NewOffersBanner;
