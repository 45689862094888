import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FaqCollapse from "pages/Support/HelpCenter/components/FaqCollapse";
import meeting from "assets/images/vastgoedverhuurmakelaardij/homepage-1.webp";
import house from "assets/images/vastgoedverhuurmakelaardij/homepage-2.webp";
import MKButton from "../../../components/MKButton";

function Faq() {
  const [collapse, setCollapse] = useState(1);

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} my={6}>
            <MKTypography variant="h3" align="center" fontWeight="bold" gutterBottom>
              In vijf stappen uw woning verhuren met Vastgoed Verhuurmakelaardij
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            pr={4}
            sx={({ breakpoints, typography: { size } }) => ({
              [breakpoints.down("lg")]: {
                display: "none",
              },
            })}
          >
            <MKBox
              component="img"
              src={meeting}
              alt="Op kantoor een meeting met een klant"
              width="100%"
              borderRadius="md"
              shadow="md"
            />
            <MKBox
              component="img"
              src={house}
              alt="Foto van de tuin"
              width="100%"
              borderRadius="md"
              shadow="md"
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FaqCollapse
              title="1. Vrijblijvend kennismakingsgesprek"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              De verhuurmakelaar geeft u adviezen omtrent de onderhoudsstaat en de
              verhuurmogelijkheden die bij uw situatie passen. Daarnaast zal een huurprijsindicatie
              afgeven worden op basis van de huidige huurmarkt. Tot slot wordt er een strategie
              bepaald die aansluit op uw wensen.
            </FaqCollapse>
            <FaqCollapse
              title="2. Professionele publicatie"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              Zodra u akkoord geeft voor het verhuren van uw woning via Vastgoed Verhuurmakelaardij
              worden er professionele foto’s van uw woning gemaakt. Deze worden samen met een
              advertentietekst op onze website en partnerwebsites geplaatst. Door onder meer onze
              samenwerking met Funda en Pararius zijn wij in staat uw woning voor een breed publiek
              te kunnen presenteren. Tevens wordt uw woning ook in ons eigen netwerk verspreid voor
              optimaal bereik.
            </FaqCollapse>
            <FaqCollapse
              title="3. Bezichtigen en selecteren van de juiste kandidaat-huurder"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              De verhuurmakelaar zal de bezichtigingen met geïnteresseerden inplannen op basis van
              uw voorkeuren. Wanneer er een concreet huurvoorstel van een kandidaat-huurder ligt zal
              de verhuurmakelaar deze beoordelen en de aangeleverde documentatie grondig verifiëren.
              Zodra wij vinden dat de kandidaat-huurder een betrouwbare partij is, presenteren wij
              het huurvoorstel aan u. Indien er onderhandeld moet worden nemen wij hier het voortouw
              in.
            </FaqCollapse>
            <FaqCollapse
              title="4. Opstellen van benodigde documentatie"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Als er een match is tussen u en de nieuwe huurder wordt de huurovereenkomst door de
              verhuurmakelaar opgesteld. Indien nodig zal deze vertaald worden in het Engels. U
              krijgt de huurovereenkomst ter controle opgestuurd. Nadat u akkoord gaat met de
              huurovereenkomst zullen de huurder en u deze ondertekenen. Wij stellen daarna de
              factuur voor de eerste betaling op voor de huurder en storten het bedrag(minus onze
              bemiddelingsvergoeding) zo spoedig mogelijk op uw rekeningnummer.
            </FaqCollapse>
            <FaqCollapse
              title="5. Uitvoeren van inspecties en sleuteloverdrachten"
              open={collapse === 5}
              onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
            >
              De verhuurmakelaar stelt bij de sleuteloverdracht een inspectierapport op. Dit rapport
              legt de onderhoudsstaat van de woning vast, zowel in schrift als in beeld. De sleutels
              worden overhandigd en de huurder betrekt de woning. Zodra de huurder opzegt plannen
              wij de voorinspectie en de eindinspectie in met de huurder. Op de einddatum stelt de
              verhuurmakelaar nogmaals een inspectierapport op en wordt u daarna begeleid in het
              afronden van het verhuurproces.
            </FaqCollapse>
          </Grid>
          <Grid container justifyContent="space-around" direction="row" alignItems="center" mt={4}>
            <Grid item>
              <MKButton
                variant="gradient"
                color="info"
                size="medium"
                sx={{ marginBottom: "10px" }}
                href="/contact-verhuren"
              >
                Meld gratis uw woning aan
              </MKButton>
            </Grid>
            <Grid item>
              <MKButton
                variant="gradient"
                color="info"
                size="medium"
                sx={{ marginBottom: "10px" }}
                href="/woning-verhuren"
              >
                Wie zijn wij?
              </MKButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Faq;
