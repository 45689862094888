/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

import { useSelector } from "react-redux";

import WoningOffer from "../../../examples/Woning";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// TODO: Implement paginate https://github.com/AdeleD/react-paginate

function Places() {
  const [searchParams] = useSearchParams();

  const [input, setInput] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [rooms, setRooms] = useState(null);

  const [filterUsed, setFilterUsed] = useState(false);

  useEffect(() => {
    // Only refresh data when the data changes

    // Set input filter
    if (searchParams.get("i")) {
      setInput(searchParams.get("i").toLowerCase());
    } else {
      setInput(null);
    }

    // Set prices filter
    if (searchParams.get("price") && searchParams.get("price").includes("-")) {
      setMinPrice(searchParams.get("price").split("-")[0]);
      setMaxPrice(searchParams.get("price").split("-")[1]);
    } else {
      setMinPrice(null);
      setMaxPrice(null);
    }

    // Set room filter
    searchParams.get("rooms") ? setRooms(searchParams.get("rooms")) : setRooms(null);

    // Set filter used flag
    setFilterUsed(searchParams.get("i") || searchParams.get("rooms") || searchParams.get("price"));
  }, [searchParams]);

  // Get woningen
  const woningen = useSelector((state) => state.woningen);
  let woningenArray = woningen.woningen;

  // Input filter
  woningenArray = input
    ? woningenArray.filter(
        (w) =>
          (w.adres.straat && w.adres.straat.toLowerCase().includes(input)) ||
          (w.adres.plaats && w.adres.plaats.toLowerCase().includes(input)) ||
          (w.adres.provincie && w.adres.provincie.replace("_", " ").toLowerCase().includes(input))
      )
    : woningenArray;

  // Price filter
  woningenArray =
    minPrice && maxPrice
      ? woningenArray.filter(
          (w) =>
            w.financieel.overdracht.huurprijs &&
            w.financieel.overdracht.huurprijs >= minPrice &&
            w.financieel.overdracht.huurprijs <= maxPrice
        )
      : woningenArray;

  // Rooms filter
  // Hardcoded 4 = 4 or more, others are exact
  woningenArray =
    rooms && parseInt(rooms)
      ? woningenArray.filter((w) =>
          parseInt(rooms) === 4
            ? parseInt(w.algemeen.aantalKamers) >= parseInt(rooms)
            : parseInt(w.algemeen.aantalKamers) === parseInt(rooms)
        )
      : woningenArray;

  const woningenHtml = woningenArray.map((woning, index) => (
    <Grid item xs={12} md={6} lg={4} key={index}>
      <WoningOffer content={woning}></WoningOffer>
    </Grid>
  ));

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          {woningen.status === "loading" && (
            <Grid item md={12} display="flex" justifyContent="center" height={300} mt={10}>
              <CircularProgress color="primary" size="5rem" />
            </Grid>
          )}
          {woningenHtml.length ? (
            woningenHtml
          ) : woningen.status === "idle" ? (
            <Grid item md={12} display="flex" justifyContent="center" height={300} mt={10}>
              <p>
                {filterUsed
                  ? "Geen woningen beschikbaar op dit moment met de huidige filters"
                  : "Geen woningen beschikbaar op dit moment"}
              </p>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {/* // TODO: Paginations */}
        {/* <MKBox mt={5}> */}
        {/*   <MKPagination> */}
        {/*     <MKPagination item> */}
        {/*       <Icon>keyboard_arrow_left</Icon> */}
        {/*     </MKPagination> */}
        {/*     <MKPagination item active> */}
        {/*       1 */}
        {/*     </MKPagination> */}
        {/*     <MKPagination item>2</MKPagination> */}
        {/*     <MKPagination item>3</MKPagination> */}
        {/*     <MKPagination item>4</MKPagination> */}
        {/*     <MKPagination item>5</MKPagination> */}
        {/*     <MKPagination item> */}
        {/*       <Icon>keyboard_arrow_right</Icon> */}
        {/*     </MKPagination> */}
        {/*   </MKPagination> */}
        {/* </MKBox> */}
      </Container>
    </MKBox>
  );
}

export default Places;
