/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import owner from "assets/images/vastgoedverhuurmakelaardij/over-ons-headshot.webp";
import CenteredBlogCardAboutUs from "./CenteredBlogCardAboutUs";
import MKTypography from "../../../../components/MKTypography";

function PersonalInformation() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            xs={12}
            lg={6}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <MKTypography variant="h3" spacing={{ xs: 4, md: 8 }} mb={1}>
              Maak kennis met de eigenaar
            </MKTypography>
            <MKTypography variant="body2">
              Ik ben Matthijs Bregman, de eigenaar en de oprichter van Vastgoed Verhuurmakelaardij
              en Bregman Vastgoedbeheer. Sinds jongs af aan heeft mijn passie voor dienstverlening
              en vastgoed zich ontwikkeld. Een stap verder zetten of persoonlijke aandacht voor
              klanten is mij daarom ook niet vreemd. Mijn missie is gericht op het verbeteren van de
              dienstverlening in de vastgoedwereld, omdat hier nog veel ruimte is voor verbetering.
              <br />
              <br />
              Na jaren ervaring te hebben opgedaan als verhuurmakelaar bij een extern
              makelaarskantoor besloot, ik mijn eigen visie te verwezenlijken. In 2022 heb ik
              Vastgoed Verhuurmakelaardij en Bregman Vastgoedbeheer opgericht om deze visie uit te
              voeren. Tot op de dag van vandaag voer ik deze visie met veel enthousiasme en passie
              uit.
              <br />
              <br />
              U kunt erop vertrouwen dat ik me volledig inzet om het beste resultaat voor u te
              behalen. Uw succes is mijn succes, en samen werken wij eraan om het beste resultaat te
              bereiken. Als u benieuwd bent naar onze dienstverlening kunt u altijd contact opnemen
              met mij opnemen.
              <br />
              <br />
              Ik kijk ernaar uit u te verwelkomen bij mijn kantoor.
            </MKTypography>
            <MKTypography variant="body2" mt={5} sx={{ fontStyle: "italic", fontWeight: "bold" }}>
              “De vastgoedmarkt staat onder druk, dit is voor iedereen voelbaar. Nieuwe wetgeving
              werpt belemmeringen op voor vastgoedbeleggers en zetten het vastgoedrendement onder
              druk. Onze focus ligt niet alleen op het vinden van de juiste huurder, maar ook op het
              leveren van een positieve bijdrage aan uw vastgoedrendement.”
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCardAboutUs
              image={owner}
              title="Matthijs Bregman"
              description="Eigenaar / Oprichter"
              linkedin="https://www.linkedin.com/in/matthijs-bregman"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default PersonalInformation;
