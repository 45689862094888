/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "../../../../components/MKTypography";

// Images
import matthijs from "assets/images/vastgoedverhuurmakelaardij/over-ons-over-ons.webp";
import insideHouse from "assets/images/vastgoedverhuurmakelaardij/over-ons-vastgoedbeheer.webp";
import werkwijze from "assets/images/vastgoedverhuurmakelaardij/over-ons-werkwijze.webp";

function Information() {
  return (
    <MKBox component="section" pt={12}>
      <Container>
        {/* Part 1 */}
        <Grid container spacing={10} pb={8}>
          <Grid item xs={12} lg={6} pb={4}>
            <MKTypography variant="h3" mb={1}>
              Over ons
            </MKTypography>
            <MKTypography variant="body2">
              Vastgoed Verhuurmakelaardij, een jong en gedreven kantoor gespecialiseerd in het
              verhuren en beheren van woningen in de regio Groot Amsterdam, met een focus op het
              midden- en duurdere segment van de huurmarkt. Onze toegewijde aandacht voor de
              ontwikkelingen in de lokale woningmarkt stelt ons in staat om niet alleen woningen
              succesvol te verhuren, maar ook om een positieve bijdrage te leveren aan uw
              vastgoedrendement.
              <br />
              <br />
              Wij hebben een vernieuwende kijk op vastgoedverhuur. Zo streven wij niet alleen naar
              een verbetering in uw vastgoedrendement, maar ook naar het verwezenlijken van uw
              vastgoedbehoeftes. Wij begrijpen de uitdagingen waarmee u wordt geconfronteerd en zijn
              toegewijd aan het op maat maken van oplossingen om uw belangen te behartigen.
              <br />
              <br />
              Wat wij u bieden is een volledige verhuur- en beheerservice, van A tot Z, inclusief
              professionele foto’s en het opstellen van meertalige huurovereenkomsten. Onze No-Cure,
              No-Pay-aanpak betekent dat u alleen betaalt als wij een geschikte huurder vinden.
              Daarnaast bieden wij een voordelig vastgoedbeheerpakket dat regelmatige grondige
              inspecties omvat, zodat u altijd op de hoogte blijft van de onderhoudsstatus van uw
              woning.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox
              component="img"
              src={matthijs}
              alt="Matthijs Bregman van Vastgoed verhuurmakelaardij aan tafel"
              width="100%"
              borderRadius="md"
              shadow="md"
            />
          </Grid>
        </Grid>

        {/* Part 2 */}
        <Grid container spacing={10} pb={8}>
          <Grid item xs={12} lg={6} display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
            <MKBox
              component="img"
              src={werkwijze}
              alt="Matthijs Bregman van Vastgoed verhuurmakelaardij aan tafel"
              width="100%"
              borderRadius="md"
              shadow="md"
            />
          </Grid>
          <Grid item xs={12} lg={6} pb={4}>
            <MKTypography variant="h3" mb={1}>
              Werkwijze
            </MKTypography>
            <MKTypography variant="body2">
              De werkwijze die wij hanteren is gebaseerd op transparantie en het No-Cure,
              No-Pay-principe, wat betekent dat u pas betaalt als Wij een geschikte huurder hebben
              gevonden. Dankzij onze samenwerking met Funda en Pararius kunnen Wij uw woning snel en
              aan een breed publiek presenteren. Wij ontvangen aanmeldingen via zowel onze
              platformen als ons uitgebreide netwerk.. Bij Vastgoed Verhuurmakelaardij kunt u
              rekenen op deskundig advies, persoonlijk contact en uitstekende begeleiding gedurende
              het hele verhuurproces.
              <br />
              <br />
              Onze toewijding gaat verder dan zakelijke transacties. Wij streven naar het opbouwen
              van sterke en betrouwbare relaties met zowel onze klanten als huurders. Als
              makelaarskantoor voelen wij de verantwoordelijkheid om uitstekende service te bieden,
              en dit vormt de kern van onze missie.
            </MKTypography>
          </Grid>
        </Grid>

        {/* Part 3 */}
        <Grid container spacing={10} pb={8}>
          <Grid item xs={12} lg={6} pb={4}>
            <MKTypography variant="h3" mb={1}>
              Bregman Vastgoedbeheer
            </MKTypography>
            <MKTypography variant="body2">
              Naast onze uitgebreide verhuurservice bieden wij ook een volledig vastgoedbeheerpakket
              tegen een zeer concurrerend tarief aan. Onze vastgoedbeheerder neemt de volledige zorg
              voor uw woning(en) op zich, inclusief financieel en technisch beheer. Dankzij onze
              periodieke controles behouden wij een duidelijk overzicht van de onderhoudstoestand
              van uw woning, en controleren wij of de huurder zich aan de afgesproken regels houdt.
              Bovendien stellen wij ons in staat om preventieve maatregelen te nemen om mogelijke
              schades of problemen voor te zijn.
              <br />
              <br />
              Wij houden nauw contact met de betreffende VvE-beheerder om werkzaamheden in
              overeenstemming met de huurder te coördineren. Dit zorgt voor een vlot proces en
              tevreden betrokkenen. Bovendien verzorgen wij de afrekeningen met betrekking tot de
              borgsom en voorschotbedragen, zoals stookkosten. Door middel van deze afrekeningen
              behoeden wij u tegen risico’s van verplichte betalingen aan externe partijen.
              <br />
              <br />
              Wij delen graag ons advies en werken samen met u aan de juiste strategie voor het
              verhuren van uw woning(portefeuille). Met onze diepgaande kennis van de markt en ons
              vermogen om situaties in te schatten, zijn wij ervan overtuigd dat wij een uitstekend
              resultaat voor u kunnen behalen.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} display={{ xs: "none", sm: "none", md: "none", lg: "block" }}>
            <MKBox
              component="img"
              src={insideHouse}
              alt="Binnenkant van een van de huizen"
              width="100%"
              borderRadius="md"
              shadow="md"
            />
          </Grid>
        </Grid>

        {/* <Grid container justifyContent="space-around" direction="row" alignItems="center" mt={4}> */}
        {/*   <Grid item> */}
        {/*     <MKButton */}
        {/*       variant="gradient" */}
        {/*       color="info" */}
        {/*       size="medium" */}
        {/*       sx={{ marginBottom: "10px" }} */}
        {/*       href="/contact-verhuren" */}
        {/*     > */}
        {/*       Meld gratis uw woning aan */}
        {/*     </MKButton> */}
        {/*   </Grid> */}
        {/*   <Grid item> */}
        {/*     <MKButton */}
        {/*       variant="gradient" */}
        {/*       color="info" */}
        {/*       size="medium" */}
        {/*       sx={{ marginBottom: "10px" }} */}
        {/*       href="/verhuur-en-beheer" */}
        {/*     > */}
        {/*       Meer weten? */}
        {/*     </MKButton> */}
        {/*   </Grid> */}
        {/* </Grid> */}
      </Container>
    </MKBox>
  );
}

export default Information;
