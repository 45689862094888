/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router components
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ContactUs from "../../../../layouts/pages/support/contact-us";
import MKSocialButton from "../../../../components/MKSocialButton";

function CenteredBlogCardAboutUs({ image, title, description, linkedin }) {
  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
        <MKBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <MKBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: "scale(0.94)",
            filter: "blur(12px)",
            backgroundSize: "cover",
          }}
        />
      </MKBox>
      <MKBox p={3} mt={-1} textAlign="center">
        <MKTypography display="inline" variant="h5" textTransform="capitalize" fontWeight="regular">
          {title}
        </MKTypography>
        <MKBox mt={1} mb={3}>
          <MKTypography variant="body2" component="p" color="text">
            {description}
          </MKTypography>
        </MKBox>
        {linkedin && (
          <MKTypography variant="body2" component="p" color="text">
            <MKTypography
              key={linkedin}
              component="a"
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              variant="h2"
              color="linkedin"
              opacity={1}
            >
              <LinkedInIcon />
            </MKTypography>
          </MKTypography>
        )}
        <MKButton component="a" href="tel:020-2212015" color="info" size="small" sx={{ mr: 2 }}>
          020-2212015
        </MKButton>
        <MKButton component="a" href="/contact" variant="gradient" size="small" color="info">
          Contact formulier
        </MKButton>
      </MKBox>
    </Card>
  );
}

// Typechecking props for the CenteredBlogCard
CenteredBlogCardAboutUs.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkedin: PropTypes.string,
};

export default CenteredBlogCardAboutUs;
