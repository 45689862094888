import React from "react";

export const formatText = (text) => {
  if (!text && typeof text !== "string") return "";
  else return text.charAt(0).toUpperCase() + text.replaceAll("_", " ").toLowerCase().slice(1);
};

export const getBadge = (status, datum) => {
  if (!status && !datum) return null;
  if (status && status === "VERHUURD") {
    return {
      text: "Verhuurd",
      color: "warning",
    };
  }
  if (datum && new Date(datum)) {
    const diffDays = (new Date() - new Date(datum)) / 1000 / 60 / 60 / 24;
    if (diffDays <= 15.0) {
      // NIEUW badge is given when datum is 15 days or less
      return {
        text: "Nieuw",
        color: "success",
      };
    }
  }
};
