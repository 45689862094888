/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

function Socials() {
  return (
    <MKBox pt={18} pb={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
            <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
              Volg onze socials en blijf op de hoogte!
            </MKTypography>
            <MKTypography variant="body1" color="text">
              Of stel je vraag via Instagram of Facebook
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            my={{ xs: 5, lg: "auto" }}
            mr={{ xs: 0, lg: "auto" }}
            sx={{ textAlign: { xs: "center", lg: "right" } }}
          >
            {/* <MKSocialButton */}
            {/*   component="a" */}
            {/*   href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-design-system-pro" */}
            {/*   target="_blank" */}
            {/*   color="twitter" */}
            {/*   sx={{ mr: 1 }} */}
            {/* > */}
            {/*   <i className="fab fa-twitter" /> */}
            {/*   &nbsp;Tweet */}
            {/* </MKSocialButton> */}
            <MKSocialButton
              component="a"
              href="https://www.facebook.com/VastgoedVerhuurmakelaardij"
              target="_blank"
              color="facebook"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-facebook" />
              &nbsp;Deel
            </MKSocialButton>
            <MKSocialButton
              component="a"
              href="https://www.instagram.com/vastgoedverhuurmakelaardij/?hl=en"
              target="_blank"
              color="instagram"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-instagram" />
              &nbsp;Like
            </MKSocialButton>
            <MKSocialButton
              component="a"
              href="https://www.linkedin.com/company/vastgoed-verhuurmakelaardij"
              target="_blank"
              color="linkedin"
            >
              <i className="fab fa-linkedin" />
              &nbsp;Bekijk
            </MKSocialButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Socials;
