import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import routes from "routes";
import { fetchWoningenAsync } from "./redux/slices/woningenSlice";
import { useDispatch } from "react-redux";
import { fetchLocatiesAsync } from "./redux/slices/locatiesSlice";
import Hotjar from "@hotjar/browser";

const siteId = 3729109;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const useGoogleAnalytics = (gaId) => {
  useEffect(() => {
    // Function to load the GA script
    const loadGAScript = () => {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", gaId);
      };
    };

    // Check if GA script is already loaded
    if (!window.gtag) {
      loadGAScript();
    }
  }, [gaId]);
};

export default function App() {
  useGoogleAnalytics(process.env.REACT_APP_GOOGLE_TRACKING_ID);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // On initialize get woningen
  useEffect(() => {
    dispatch(fetchWoningenAsync());
    dispatch(fetchLocatiesAsync());
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route, i) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={i} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
