// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKBadge from "components/MKBadge";

// Material Kit 2 PRO React components
import SimpleReviewCard from "examples/Cards/ReviewCards/SimpleReviewCard";

import "./styles.css";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";
import woningGraafAelbrechtlaan from "assets/images/vastgoedverhuurmakelaardij/graaf-aelbrechtlaan.jpg";
import woningZeelandStraat from "assets/images/vastgoedverhuurmakelaardij/zeelandstraat.jpg";
import woningSerooskerkenweg from "assets/images/vastgoedverhuurmakelaardij/serooskerkenweg.jpg";

function Testimonials() {
  return (
    <MKBox
      position="relative"
      variant="gradient"
      bgColor="dark"
      py={2}
      mx={-2}
      className="testimonials"
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="Klantervaringen"
              variant="contained"
              color="#ffce4d"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            {/* <MKTypography variant="h2" color="white" mb={1}> */}
            {/*   Testimonials */}
            {/* </MKTypography> */}
            <MKTypography variant="body1" color="white" fontWeight="light">
              Lees de ervaringen van klanten die u voor waren
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mb: 6 }} justifyContent="center">
          <Grid item xs={12} md={12} lg={6} xl={3} mt={6}>
            <SimpleReviewCard
              image={woningGraafAelbrechtlaan}
              name="Mervyn Brookson"
              review="“Vastgoed Verhuurmakelaardij heeft ons geholpen met de verhuur van onze woning. Zeer tevreden met hoe dit verliep. Matthijs is altijd vriendelijk en professioneel, heeft foto's en teksten (NL en EN) van hoge kwaliteit gemaakt. Communiceert vlot en pakt zaken direct op en wist meerdere geschikte kandidaten te vinden waardoor onze woning heel vlot verhuurd was. Ik schrijf niet snel een review, maar ik heb geen enkele moeite om Matthijs aan te bevelen.”"
              stars={5}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={3} mt={6}>
            <SimpleReviewCard
              image={woningZeelandStraat}
              name="Sebastian Barth"
              review="“Matthijs, did an outstanding job in renting out our apartment! The whole process was very structured and organized: - very good intake incl. recommendations what to take care of in the house before renting out - professional photos plus description of the apartment in the posting - thorough and autonomous pre-selection of candidates - impactful recommendation for the final candidate incl. final negotiation - fast and comprehensive handling of the contract Furthermore, we are also very happy with the house management service! Thanks!”"
              stars={5}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={3} mt={6}>
            <SimpleReviewCard
              image={woningSerooskerkenweg}
              name="Cees Toor"
              review="“Vastgoedverhuurmakelaardij van Matthijs Bregman is een topper. Terwijl een grote makelaardij in 8 weken geen huurders kon aanleveren voor mijn appartement, lukte het Matthijs vervolgens om binnen 3 dagen na hem te hebben ingeschakeld 3 huurderkandidaten van hoge kwaliteit hiervoor aan te leveren. Matthijs is zeer professioneel, daadkrachtig, werkt proactief, en levert snel concreet resultaat.
Ik raad Vastgoedverhuurmakelaardij van harte aan.”"
              stars={5}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;
