/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "routes";
import footerRoutes from "footer.routes";
import bgImage from "assets/images/vastgoedverhuurmakelaardij/regus-kantoor-foto.webp";
import ContactForm from "../../../examples/ContactForms/ContactForm";
import MKTypography from "../../../components/MKTypography";
import Container from "@mui/material/Container";
import MKButton from "../../../components/MKButton";
import GoogleMapsWrapper from "../../../examples/GoogleMaps";

function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%" sx={{ zIndex: 111 }}>
        <DefaultNavbar
          routes={routes}
          action={{
            type: "call",
            route: "",
            label: "020-2212015",
            color: "info",
          }}
          sticky
        />
      </MKBox>
      <GoogleMapsWrapper />

      <MKBox component="section" py={{ xs: 0, lg: 6 }} mt={{ xs: 5, md: -15 }} zIndex={200}>
        <Container>
          <Grid container item px={{ xs: 0, md: 6 }}>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                  <MKBox>
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}></MKBox>
                    <ContactForm padding={0} />
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.info.main, 0.8),
                        rgba(gradients.info.state, 0.8)
                      )}, url(${bgImage})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Contact informatie
                      </MKTypography>
                      <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                        Vul het formulier in en wij zullen zo snel mogelijk op uw bericht reageren!
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          020 - 2997900
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          info@vastgoedverhuurmakelaardij.nl
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          Van Heuven Goedhartlaan 13D
                          <br /> 1181 LE Amstelveen
                        </MKTypography>
                      </MKBox>
                      <MKBox mt={3}>
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          href="https://www.facebook.com/VastgoedVerhuurmakelaardij"
                          target="_blank"
                          iconOnly
                        >
                          <i className="fab fa-facebook" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          href="https://www.instagram.com/vastgoedverhuurmakelaardij"
                          target="_blank"
                          iconOnly
                        >
                          <i className="fab fa-instagram" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          href="https://www.linkedin.com/company/vastgoed-verhuurmakelaardij"
                          target="_blank"
                          iconOnly
                        >
                          <i className="fab fa-linkedin" style={{ fontSize: "1.25rem" }} />
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
