/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Grid from "@mui/material/Grid";
import { useState } from "react";
import Card from "@mui/material/Card";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, InputLabel, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "./styles.css";
import { useNavigate, useSearchParams } from "react-router-dom";

function SearchBar() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [baseInput, setBaseInput] = useState(searchParams.get("i") || "");
  const [price, setPrice] = useState(searchParams.get("price") || "");
  const [rooms, setRooms] = useState(searchParams.get("rooms") || "");

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let url = `/aanbod?i=${baseInput}`;
    if (price) url += `&price=${price}`;
    if (rooms) url += `&rooms=${rooms}`;
    navigate(url);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        lg={8}
        mx="auto"
        mt={-6}
        sx={{ textAlign: { xs: "center", lg: "center" } }}
      >
        <Card>
          <form onSubmit={handleSearchSubmit}>
            <Grid px={2} my={2} container>
              <Grid item xs={12} lg={4} p={1}>
                <Box className="search-bar-location-text-input">
                  <MKInput
                    variant="standard"
                    label="Plaats of straat "
                    value={baseInput}
                    onChange={(e) => setBaseInput(e.target.value)}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={1}>
                <FormControl fullWidth className="search-bar-dropdown-input">
                  <InputLabel id="price">Prijs p/m</InputLabel>
                  <Select
                    labelId="price"
                    label="Prijs p/m"
                    className="search-bar-dropdown-input-input"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  >
                    <MenuItem value="">Geen voorkeur</MenuItem>
                    <MenuItem value="0-1500">&euro; 0 - 1500</MenuItem>
                    <MenuItem value="1500-2500">&euro; 1500 - 2500</MenuItem>
                    <MenuItem value="2500-99999">&euro; 2500 +</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={3} p={1}>
                <FormControl fullWidth className="search-bar-dropdown-input">
                  <InputLabel id="rooms">Aantal kamers</InputLabel>
                  <Select
                    labelId="rooms"
                    label="Kamers"
                    className="search-bar-dropdown-input-input"
                    value={rooms}
                    onChange={(e) => setRooms(e.target.value)}
                  >
                    <MenuItem value="">Geen voorkeur</MenuItem>
                    <MenuItem value={1}>1 kamer</MenuItem>
                    <MenuItem value={2}>2 kamer</MenuItem>
                    <MenuItem value={3}>3 kamer</MenuItem>
                    <MenuItem value={4}>4+ kamer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2} p={1}>
                <MKButton
                  variant="gradient"
                  color="info"
                  size="large"
                  sx={{ width: "100%" }}
                  type="submit"
                >
                  <SearchIcon sx={{ mr: 1 }} />
                  Zoeken
                </MKButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
}

export default SearchBar;
