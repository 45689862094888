import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchLocatiesAsync = createAsyncThunk("locaties/fetchLocaties", async () => {
  try {
    return await axios
      .get(process.env.REACT_APP_API_URL + "/wonen/locaties", {})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return [];
      });
  } catch (e) {
    return [];
  }
});

export const locatiesSlicer = createSlice({
  name: "locaties",
  initialState: {
    status: "loading",
    locaties: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocatiesAsync.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchLocatiesAsync.fulfilled, (state, action) => {
        state.locaties = action.payload;
        state.status = "idle";
      })
      .addCase(fetchLocatiesAsync.rejected, (state, action) => {
        state.status = "error";
      });
  },
});

export default locatiesSlicer.reducer;
