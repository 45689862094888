// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { Typography } from "@mui/material";
import MKButton from "../../components/MKButton";

function CallToAction() {
  return (
    <MKBox position="relative" variant="gradient" bgColor="dark" py={2} mx={-2}>
      <MKBox width="100%" opacity={0.2} display={{ xs: "none", md: "block" }} />
      <Container>
        <Grid
          container
          justifyContent="space-between"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center", pt: 8, pb: 10 }}>
            <MKTypography variant="h2" color="white" mb={1}>
              Op zoek naar een huurwoning?
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light" sx={{ pb: 3 }}>
              Bekijk ons aanbod in onder meer Amsterdam, Amstelveen, Ouderkerk a/d Amstel, Haarlem
              en omstreken of neem direct contact op met Vastgoed Verhuurmakelaardij.
            </MKTypography>
            <MKButton variant="contained" color="info" size="large" key="/aanbod" href="/aanbod">
              Bekijk ons aanbod
            </MKButton>
          </Grid>
          <Grid item xs={12} md={6} sx={{ textAlign: "center", pt: 8, pb: 10 }}>
            <MKTypography variant="h2" color="white" mb={1}>
              Uw woning verhuren?
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light">
              Benieuwd naar wat Vastgoed Verhuurmakelaardij voor u kan doen? Meldt uw woning aan of
              neem direct contact op met ons kantoor:{" "}
            </MKTypography>
            <MKTypography variant="body1" color="white" fontWeight="light" sx={{ pb: 3 }}>
              020-2212015
            </MKTypography>
            <MKButton
              variant="contained"
              color="info"
              size="large"
              key="/contact-verhurenr"
              href="/contact-verhuren"
            >
              Neem contact op
            </MKButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default CallToAction;
